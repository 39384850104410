<template>
	<div class="familyChooseContent">
		<img class="top" src="../assets/images/familyTop.png" alt="">
		<ul class="familyList">
			<li v-for="(item,index) in family" :key="index" >
				<img class="icon" :src="item.avatarUrl as string" alt=""/>
				<div class="info">
					<span class="name">{{item.memberName}}<em class="relationship">{{item.relationship}}</em></span>
					<p>{{item.sex}}&nbsp;&nbsp;&nbsp;{{item.age}}岁</p>
				</div>
				<img @click="choose(index)" class="check" :src="currentCheck===index?selectedAwy:selectAwy" alt=""/>
			</li>
		</ul>
		<span @click="sure" :class="{bnt:true,disable:currentCheck===undefined}">确认选择</span>
	</div>
</template>
<script setup lang="ts">
import {ref} from 'vue'
import selectAwy from "../assets/images/selectAiwuyou.png"
import selectedAwy from "../assets/images/selectedAiwuyou.png"
import store from '../store';
import router from '../router';
const currentCheck=ref();
const family=ref();
//获取列表信息
store.dispatch("familyCard",2).then(res=>{
    family.value=res.data.members;
})
const choose=(index:number)=>{
    currentCheck.value=index;
}
const sure=()=>{
    if(currentCheck.value===undefined){
        return;
    }
    const chooseItem=family.value[currentCheck.value as any]
	// 重设个人信息
    let queryData = {
        policyNo: chooseItem.policyNo,
        userName: chooseItem.userName
    };
    store.commit("upQuery", queryData);
    sessionStorage.setItem("upQuery", JSON.stringify(queryData));
    router.push("/recordList");
}
</script>
<style scoped>
.top{
	display: block;
	width: 100vw;
}

.familyList{
    width: 86.93vw;
    margin: auto;
    padding:1vw 5vw 0 5vw;
	margin-top: -15vw;
}
.familyList li{
    box-shadow: 0 0 2vw 0 rgba(0,0,0,0.16);
    border-radius: 2vw;
    margin-bottom: 3.2vw;
    display:flex;
    padding: 4.27vw 4.5vw;
    position: relative;
	background: #fff;
}
.familyList li .icon{
    display: block;
    width: 12.4vw;
}
.familyList li .info{
    margin-left: 4.27vw;
}
.familyList li .info .name{
    font-size: 4.27vw;
    font-weight: bold;
    color: #2A2A2A;
    display: flex;
}
.familyList li p{
    color: #B5B5B5;
    font-size: 3.2vw;
    margin-top: 3vw;
}
.familyList li .relationship{
    color: #B5B5B5;
    font-size: 3.2vw;
    font-style: normal;
    height: 5.47vw;
    width: 10.53vw;
    border:0.13vw solid #B5B5B5;
    font-weight: normal;
    display: block;
    border-radius: 1.33vw;
    text-align: center;
    line-height: 5.47vw;
    margin-left: 3vw;
}
.familyList li .check{
    position: absolute;
    display: block;
    width: 5.33vw;
    top:4.8vw;
    right: 5.7vw;
}
.bnt{
    display: block;
    width: 87.2vw;
    height: 9.87vw;
    background: linear-gradient(0deg, #B01F24, #E5575C);
    border: 0.13vw solid #FFFFFF;
    box-shadow: 0 1vw 0 0 rgba(7,0,2,0.08);
    border-radius: 1.33vw;
    margin:11.2vw auto 0vw auto;
    font-size: 4.27vw;
    font-weight: bold;
    text-align: center;
    line-height: 9.87vw;
    color: #fff;
}
.bnt.disable{
    opacity: 0.5;
}
.familyChooseContent{
	padding-bottom: 5vw;
}
</style>