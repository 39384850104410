import request from '../../../api/request';
import {Module} from 'vuex'
import {RootState} from '../../interface'

const expresseModule:Module<any,RootState>={
  state: {
  },
  actions: {
    expressCompany: ({commit},data) => {
      return new Promise((resolve, reject)=>{
          request.get('/api/v1/app/advance-leave-hospital/express/company',data).then(res =>{
              resolve(res)
          })
      })
    },
    addExpressCompany:({commit},data)=>{
      return new Promise((resolve, reject)=>{
        request.post('/api/v1/app/advance-leave-hospital/express',data).then(res =>{
            resolve(res)
        })
      })
    },
    expressList: ({commit},recordId) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/app/advance-leave-hospital//express/info/${recordId}`).then(res =>{
              resolve(res)
          })
      })
    }
  }
  
}

export default expresseModule

  