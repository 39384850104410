<template>
  <div class="servicePage pb40">
    <img class="imgBlock" :src="Banner" alt="" />
    <div class="ser_title">服务内容</div>
    <div class="ser_intro">
      <div class="ser_intro_title">
        <div class="name">服务名称</div>
        <div class="content">服务说明</div>
      </div>
      <div class="ser_intro_content">
        <div class="name">愈健未来<br />康复权益包</div>
        <div class="content">
          家庭医生：由具备至少5年临床工作经验的自有全科医生担任患者管理计划统筹管理者，协助被保险人提供相关病例资料，建立个人健康档案，并进行持续的健康指标监测以及健康风险评估，根据被保险人的病情及需求，为其提供日常健康咨询、用药指导以及健康教育，并协同主管医生制定风险窗口期的患者管理计划。<br />
          主管医生：三甲医院在职医生通过图文方式为被保险人提供一对一的日常疾病问诊、报告解读、健康指导、症状管理、就医指导、用药指导术后营养康复指导等医疗咨询，利用专科医生的临床优势，提供系统性、针对性的医疗咨询服务，对客户的健康进行全面管理，并与家庭医生协同制定患者管理计划。<br />
          远程会诊：针对病情复杂，术后康复过程缓慢的被保险人，可由家庭医生根据实际情况，为其协调安排北、上、广、深、成等医疗资源发达地区三甲医院副主任及以上的相关专家，为客户进行远程多学科会诊，并输出专业的诊疗方案。<br />
          康复师：三甲医院在职护士通过电话、图文、视频方式为被保险人提供一对一的健康指导、症状管理、营养康复指导等医疗咨询，并为其制定上门康复管理计划，保障患者享受最佳的术后康复环境。<br />
          院后护理：根据康复师制定的康复计划，为患者协调安排专业康复师进行上门服务。服务内容包括：伤口换药、外科伤口拆线、上门打针、静脉采血、压疮护理及指导、留置胃管、留置导尿、灌肠护理及指导、雾化护理、吸痰护理及指导、造口护理、PICC护理、膀胱冲洗、口腔护理、心脑血管疾病护理及指导。<br />
          智能监测设备：根据被保险人的病情及需求，为其提供7*24小时健康数据监测智能设备，包括日间可穿戴设备（1台）、睡眠监测仪（1台），患者健管指标数据及行为数据实时回传，根据患者身体指标变化情况，进行后期管理计划的适当调整，以保障最佳康复效果。<br />
          康复用品：联合知名医疗器械供应商，建立康复用品及辅助器具生态体系，保障患者在术后康复期内所需用的一切康复用品及辅助器具需求。<br />
          康复知识库：自建康复、护理、疾病、养生健康知识素材库，可根据被保险人罹患的不同病种所需，进行知识的定向推送。
        </div>
      </div>
    </div>
    <div class="ser_detail">
      <div class="topBar"></div>
      <div class="intro_wrap">
        <div class="intro_item">
          <div class="name">服务次数</div>
          <div class="content">
            家庭医生、主管医生、康复师咨询不限次；远程会诊限3次；院后护理服务限10次；智能监测设备限1部日间监测设备，1部夜间监测设备；康复用品限额不限次，限1500元/年；康复知识库不限次；
          </div>
        </div>
        <div class="intro_item">
          <div class="name">服务时效</div>
          <div class="content">
            出院前提前2天申请，出院当天进行服务启动，为期1-3个月
          </div>
        </div>
        <div class="intro_item">
          <div class="name">服务启动条件</div>
          <div class="content">
            需客户线上提交公立医院出具的I-IV期宫颈癌诊断证明，方可申请使用。
          </div>
        </div>
        <div class="intro_item">
          <div class="name">服务区域</div>
          <div class="content">全国</div>
        </div>
        <div class="intro_item">
          <div class="name">服务方式及流程</div>
          <div class="content">
            由客户本人通过“珊瑚健康管家“微信公众号，根据服务申请提示，填写必要的个人信息、上传由公立医院出具的诊断证明，审核通过后，将在第一时间内为客户安排服务。
          </div>
        </div>
      </div>
    </div>
    <div class="styleBar"></div>
    <div class="styleBar1"></div>
    <div class="bntBox">
      <span @click="getService" class="getService"
        ><img :src="UseSer" alt=""
      /></span>
      <span @click="goRecord" class="record"
        ><img :src="MyOrder" alt=""
      /></span>
    </div>
    <Alert v-if="alertFlag" :alertData="alertData" @sure="alertSure"></Alert>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive } from "vue";
import Banner from "../assets/images/1673/YJWL.png";
import UseSer from "../assets/images/1673/useSer.png";
import MyOrder from "../assets/images/1673/myOrder.png";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { AlertData } from "../components/interface/Alert-d";
import waringImg from "../assets/images/waring.png";
const router = useRouter();
const store = useStore();

let queryData = {
  policyNo: router.currentRoute.value.query.policyNo,
  userName: router.currentRoute.value.query.userName,
};
store.commit("upQuery", queryData);
sessionStorage.setItem("upQuery", JSON.stringify(queryData));

const requirementSource =
  router.currentRoute.value.query.requirementSource || "";
sessionStorage.setItem("requirementSource", requirementSource.toString());
const alertData = reactive<AlertData>({
  alertTitle: "温馨提示",
  alertContent: "您在服务等待期，不能使用服务",
  bntText: "我知道了",
  pwidth: 525,
  alertType: "alert",
  icon: waringImg,
});
const alertFlag = ref<boolean>(false);
const subFlag = ref(true);
const getService = () => {
  getServiceInfo();
};

const getServiceInfo = () => {
  if (!subFlag.value) return;
  subFlag.value = false;
  store
    .dispatch("useService")
    .then((res) => {
      sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      router.push({
        path: "/apply",
        query: { type: "frHome", channel: "GJWA" },
      });
      subFlag.value = true;
    })
    .catch(() => {
      subFlag.value = true;
    });
};
const alertSure = () => {
  alertFlag.value = false;
};
const goRecord = () => {
  router.push("/recordList");
};
</script>
<style scoped>
.imgBlock {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.servicePage {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.servicePage.pb40 {
  padding-bottom: 6.6vw;
}
.servicePage .ser_title {
  width: 66.53vw;
  height: 8vw;
  background-image: url(../assets/images/1673/arrow2.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  margin-top: 5.73vw;
  text-align: center;
  font-size: 5.87vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  line-height: 8vw;
  letter-spacing: 0.8vw;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.servicePage .ser_intro {
  width: 93.6vw;
  margin: 0 auto;
  margin-top: 5.3vw;
  box-shadow: 0 0 1.2vw rgba(0, 0, 0, 0.25);
  border-radius: 1.3vw;
  padding: 0.67vw 0.53vw 0;
  background-color: #fff;
}
.servicePage .ser_intro_title {
  width: 100%;
  height: 9.2vw;
  line-height: 9.2vw;
  display: flex;
  flex-direction: row;
  background-color: #ef8994;
  font-size: 4.27vw;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 1.33vw;
}
.servicePage .ser_intro_title .name {
  width: 31.46vw;
  height: 100%;
  position: relative;
}
.servicePage .ser_intro_title .name::after {
  content: "";
  width: 0.13vw;
  height: 7.2vw;
  background-color: #fff;
  position: absolute;
  top: 1vw;
  right: 0;
}
.servicePage .ser_intro_title .content {
  flex: 1;
  min-width: 0;
  height: 100%;
}
.ser_intro_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3.47vw;
  line-height: 4.67vw;
  padding: 2.8vw 0;
  align-items: center;
}
.ser_intro_content .name {
  width: 31.46vw;
  text-align: center;
  color: #000;
  padding: 3.2vw 0;
}
.ser_intro_content .content {
  flex: 1;
  min-width: 0;
  color: #666;
  padding: 3.2vw;
  text-align: left;
  position: relative;
}
.ser_intro_content .content::before {
  content: "";
  width: 0.13vw;
  height: 100%;
  background-color: #e5e5e5;
  position: absolute;
  top: 1vw;
  left: 0;
}

.servicePage .ser_detail {
  width: 93.6vw;
  margin: 0 auto;
  margin-top: 4.8vw;
  border-radius: 1.3vw;
  padding: 0.26vw 0.4vw 0;
  background-color: #fff;
  box-shadow: 0 0 1.2vw rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 10;
}
.servicePage .styleBar {
  width: 90vw;
  height: 2.1vw;
  box-shadow: 0 1.2vw 1.2vw rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0 0 1.3vw 1.3vw;
  position: relative;
  z-index: 1;
}
.servicePage .styleBar1 {
  width: 86vw;
  height: 1.6vw;
  box-shadow: 0 1.2vw 1.2vw rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0 0 1.3vw 1.3vw;
}
.servicePage .ser_detail .topBar {
  width: 100%;
  height: 1.47vw;
  background-color: #f5c6c4;
  border-radius: 0.73vw;
}
.ser_detail .intro_wrap {
  padding: 0 3.47vw 1vw 3.73vw;
  margin-top: 1.3vw;
}
.ser_detail .intro_wrap .intro_item {
  padding: 3.2vw 0;
  line-height: 5.2vw;
  background-image: url(../assets/images/1673/dotted.png);
  background-repeat: no-repeat;
  background-size: 65.73vw 0.26vw;
  background-position: right bottom;
  display: flex;
  flex-direction: row;
}
.ser_detail .intro_wrap .intro_item:last-child {
  background-image: none;
}
.ser_detail .intro_wrap .intro_item .name {
  width: 34.13vw;
  height: 5.2vw;
  background-color: #ef8994;
  border-radius: 2.6vw;
  font-size: 3.2vw;
  color: #fff;
  font-weight: 500;
  text-align: center;
  position: relative;
  padding-left: 6.4vw;
}
.ser_detail .intro_wrap .intro_item .name::before {
  content: "";
  width: 9.06vw;
  height: 9.06vw;
  background-image: url(../assets/images/1673/arrow1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -1.93vw;
  left: -2.4vw;
}
.ser_detail .intro_wrap .intro_item .content {
  flex: 1;
  min-width: 0;
  font-size: 3.2vw;
  color: #000;
  line-height: 4.67vw;
  padding-left: 4.4vw;
}

.useServiceBtn {
  width: 93.73vw;
  height: 12.4vw;
  margin: 0 auto;
  outline: none;
  margin-top: 6.4vw;
  position: relative;
}
.useServiceBtn img {
  width: 100%;
  height: 100%;
}

.bntBox {
  width: 91vw;
  margin: auto;
  margin-top: 8.4vw;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4.37vw;
}
.bntBox span {
  display: block;
  width: 44.5vw;
  height: 12.9vw;
}
.bntBox span img {
  width: 100%;
  height: 100%;
}
</style>
