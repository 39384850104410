<template>
	<div class="cityList">
		<table >
			<tr>
				<th>区域</th>
				<th class="province">省份</th>
				<th>覆盖城市（市辖区范围内，不含下辖县、县级市）</th>
			</tr>
			<tr v-for="item in tableData">
				<td>{{item.area}}</td>
				<td colspan="2" class="tdBox">
					<tr  v-for="itemP in item.children">
						<td class="province" >{{itemP.province}}</td>
					
						<td >{{itemP.citys}}</td>
					</tr>
				</td>
			</tr>
		</table>
	</div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { ref } from 'vue';
const tableData=[
    {	"area":"华北地区",
		"children":[
			{"province":"北京市","citys":"北京市"},
			{"province":"天津市","citys":"天津市"},
			{"province":"河北省","citys":"石家庄、唐山、秦皇岛、张家口、承德、沧州、邯郸、邢台、保定、廊坊、衡水"},
			{"province":"山西省","citys":"太原、大同、晋城、朔州、忻州、阳泉、长治、吕梁、晋中、临汾、运城"},
			{"province":"内蒙古","citys":"呼和浩特、包头、呼伦贝尔、通辽、乌海、赤峰、乌兰察布、鄂尔多斯、巴彦淖尔、锡林郭勒、兴安"}
		]
	},
	{	"area":"华东地区",
		"children":[
			{"province":"上海市","citys":"上海市"},
			{"province":"江苏省","citys":"南京、苏州、无锡、常州、徐州、南通、连云港、淮安、盐城、扬州、镇江、泰州、宿迁"},
			{"province":"浙江省","citys":"杭州、宁波、温州、绍兴、嘉兴、湖州、金华、衢州、台州、丽水、舟山"},
			{"province":"安徽省","citys":"合肥、芜湖、蚌埠、淮南、马鞍山、淮北、铜陵、安庆、黄山、阜阳、宿州、滁州、六安、宣城、池州、毫州"},
			{"province":"福建省","citys":"福州、厦门、漳州、泉州、莆田、龙岩、三明、南平、宁德"},
			{"province":"江西省","citys":"南昌、鹰潭、赣州、九江、景德镇、宜春、吉安、上饶、抚州、萍乡、新余"},
			{"province":"山东省","citys":"济南、青岛、烟台、潍坊、淄博、枣庄、东营、济宁、泰安、威海、日照、滨州、临沂、菏泽、莱芜、德州、聊城"}
		]
	},
	{	"area":"华中地区",
		"children":[
			{"province":"河南省","citys":"郑州、洛阳、开封、平顶山、安阳、鹤壁、新乡、焦作、濮阳、许昌、漯河、周口、驻马店、南阳、信阳、三门峡、商丘"},
			{"province":"湖北省","citys":"武汉、黄石、襄阳、黄冈、十堰、荆州、宜昌、鄂州、荆门、孝感、咸宁、随州、恩施、潜江、天门、仙桃、神农架"},
			{"province":"湖南省","citys":"长沙、株洲、湘潭、衡阳、邵阳、岳阳、张家界、益阳、常德、娄底、郴州、永州、怀化、湘西土家族苗族自治州"},
		]
	},
	{	"area":"华南地区",
		"children":[
			{"province":"广东省","citys":"广州、深圳、珠海、佛山、东莞、中山、汕头、韶关、湛江、肇庆、江门、茂名、惠州、梅州、汕尾、河源、阳江、清远、潮州、揭阳、云浮"},
			{"province":"广西省","citys":"南宁、柳州、桂林、梧州、北海、崇左、来宾、贺州、玉林、百色、河池、钦州、防城港、贵港"},
			{"province":"海南省","citys":"海口、三亚、三沙、儋州、昌江、琼海、万宁"}
		]
	},
	{	"area":"西南地区",
		"children":[
			{"province":"重庆市","citys":"重庆市"},
			{"province":"四川省","citys":"成都、绵阳、自贡、攀枝花、泸州、德阳、广元、遂宁、内江、乐山、资阳、宜宾、南充、达州、雅安、广安、巴中、眉山、凉山"},
			{"province":"贵州省","citys":"贵阳、六盘水、遵义、铜仁、毕节、安顺、黔东南苗族侗族自治州、黔南布依族苗族自治州、黔西南布依族苗族自治州"},
			{"province":"云南省","citys":"昆明、昭通、曲靖、玉溪、普洱、保山、丽江、临沧、楚雄、大理、德宏、红河、文山、西双版纳"},
			{"province":"西藏","citys":"拉萨、昌都、山南、日喀则、那曲、林芝、阿里"},
		]
	},
	{	"area":"西北地区",
		"children":[
			{"province":"陕西","citys":"西安、铜川、宝鸡、咸阳、渭南、汉中、安康、商洛、延安、榆林"},
			{"province":"甘肃","citys":"兰州、嘉峪关、金昌、白银、天水、酒泉、张掖、武威、定西、陇南、平凉、庆阳"},
			{"province":"青海","citys":"西宁、海东、海西、海北、海南"},
			{"province":"宁夏","citys":"银川、石嘴山、吴忠、固原、中卫"},
			{"province":"新疆","citys":"乌鲁木齐、克拉玛依、吐鲁番、哈密、阿克苏、阿勒泰、博尔塔拉、昌吉、库尔勒、石河子、伊犁"},
		]
	},
	{	"area":"东北地区",
		"children":[
			{"province":"辽宁省","citys":"沈阳、大连、鞍山、抚顺、本溪、丹东、锦州、营口、阜新、辽阳、盘锦、铁岭、朝阳、葫芦岛"},
			{"province":"吉林省","citys":"长春、吉林、四平、辽源、通化、白山、白城、松原、延边"},
			{"province":"黑龙江省","citys":"哈尔滨、齐齐哈尔、伊春、牡丹江、佳木斯、大庆、鸡西、鹤岗、双鸭山、七台河、绥化、黑河"}
		]
	}					
]
const listData=ref()
axios.get('./citysHS.json').then(res=>{
	console.log(res)
    listData.value=res.data;
})
</script>
<style scoped>
table{
	border-right:#ddd solid 1px;
	border-bottom:#ddd solid 1px;
	width: 90%;
	margin: auto;
	font-size: 3.2vw;
	
}
table td,table th{
	border-left:#ddd solid 1px;
	border-top:#ddd solid 1px;
	padding: 2vw;
}
.tdBox{
	padding: 0;
	border-right:none
}
.province{
	width: 10vw;
	
	
}
td.province{
	border-right: none;
	border-left:none;
}
.cityList{
	padding: 10vw 0;
	background: #fff;
}
.tdBox tr:last-child{
	border-bottom: none;
}
</style>