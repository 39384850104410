<template>
	<div id="ewm">
		<img class="top" src="../assets/images/dataNullTop.png" alt=""/>
		<!-- <qrcode-vue v-if="(!ewmUrl||ewmUrl==='')&&ewmValue" ref="ewmBox" :value="ewmValue" :size="300" level="H" />
		<img class="ewm" v-if="ewmUrl&&ewmUrl!==''" :src="ewmUrl" alt=""> -->
		<img class="ewm" v-if="ewmUrl&&ewmUrl!==''" :src="ewmUrl" alt=""> 
		<p class="discript">请长按识别上方小程序码<br/>进入服务使用通道！</p>
	</div>
</template>
<script setup lang="ts">
import { ref,onMounted } from 'vue';
import QrcodeVue from "qrcode.vue"

import store from '../store';
const ewmBox=ref();
const ewmValue=ref();
const ewmUrl=ref();
onMounted(()=>{
	store.dispatch("getQRCode").then(res1=>{
		// ewmValue.value=res1.data.url;
		// setTimeout(()=>{
		// 	ewmUrl.value=document.querySelector("canvas")?.toDataURL();
		// },1000)
		ewmUrl.value=res1.data.url
	})


})

</script>
<style scoped>
#ewm{
	height: 100%;
	background: #fafafa;
}
.top{
	display: block;
	width: 100%;
}
.ewm,canvas{
	display: block;
	width: 50.13vw!important;
	height: 50.13vw!important;
	margin: 20vh auto 15vw auto;
}
.discript{
	font-size: 4.27vw;
	color: #0E0E0E;
	/* font-weight: bold; */
	text-align: center;
	line-height: 7vw;
}
</style>