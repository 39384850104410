<template>
	<div id="record" v-if="initFlag">
		<div v-if="listFlag">
			<img class="top" src="../assets/images/recordTop.png" alt=""/>
			<ul class="tab">
				<li v-for="item in tabList" @click="changeTab(item.code)" :class="{current:item.code===currentTab}">
					{{item.name}}
				</li>
			</ul>
			<div v-for="item in listData" :class="'recordList'+' state'+item.serviceStatus" @click="linkFor(item)">
				<ul>
					<li>
						<span class="title">会员姓名：</span>
						<span class="detail name">{{item.userName}}</span>
					</li>
					<li>
						<span class="title">医院名称：</span>
						<span class="detial">{{item.hospitalName}}</span>
					</li>
					<li>
						<span class="title">报案时间：</span>
						<span class="detial">{{item.createTime}}</span>
					</li>
				</ul>
				<span class="progressNode">{{getState(store.state.recordModule.progressNode,item.serviceStatus,'getText')}}</span>
				<span v-if="item.serviceStatus===1" class="outCase" @click.stop="outCase(item)">撤销报案</span>
			</div>
			<div v-if="listData.length===0" class="dataNullInfo">
				<img src="../assets/images/dataNull.png" class="dataNull" alt=""/>
				<span>暂无任何记录</span>
			</div>
		</div>
		<div v-if="!listFlag" class="dataNullBox">
			<img src="../assets/images/dataNullTop.png" class="top" alt=""/>
			<div class="dataNullInfo">
				<img src="../assets/images/dataNull.png" class="dataNull" alt=""/>
				<span>暂无任何记录</span>
			</div>
		</div>
	</div>
	<alert v-if="alertFlag" :alertData="alertData" @sure="alertSure" @cancel="alertCancel"></alert>
</template>
<script setup lang="ts">
import { ref ,onMounted,reactive} from 'vue';
import {useStore} from 'vuex';
import { RecordItem } from './interface/record-d';
import router from '../router';
import { getState } from "../utils/index";
import { AlertData } from '../components/interface/Alert-d';
import waringImg from "../assets/images/waring.png"
const store=useStore();
const tabList=[
	{"name":"进行中的服务","code":1},
	{"name":"已结束的服务","code":2}
]
const alertData=reactive<AlertData>({
	alertTitle:"温馨提示",
	alertContent: "111",
	bntText:"我知道了",
	pwidth:525,
	alertType:"alert",
	icon:waringImg
})
const alertFlag=ref(false);
const alertSure=()=>{
	alertFlag.value=false;
	if(alertData.alertContent==="确认是否撤销已申请成功报案的记录"){
		outCaseSure();
	}
}
const alertCancel=()=>{
	alertFlag.value=false
}
const currentTab=ref(1);
const changeTab=(code:number)=>{
	currentTab.value=code;
	getData(code)
}
const initFlag=ref(false);
const listData=ref<RecordItem[]>([])

const getData=(_type:number)=>{
	store.dispatch("advanceRecord",_type).then(res=>{
		listData.value=res.data;
		initFlag.value=true;
	}).catch(res=>{
		if(res.code===400007009){
			listFlag.value=false
		}
		initFlag.value=true;
	})
}
// 页面初始化
getData(1)
const cancelSheetId=ref()
const outCase=(item:RecordItem)=>{
	alertData.alertContent="确认是否撤销已申请成功报案的记录"
	alertData.alertType="comfirm";
	alertData.bntText="确认";
	alertData.cancelBnt="返回"
	alertFlag.value=true;
	cancelSheetId.value=item.sheetId;
}
const outCaseSure=()=>{
	store.dispatch("cancelApply",cancelSheetId.value).then(res=>{
		getData(1)
	})
}
const listFlag=ref(true)
const linkFor=(item:RecordItem)=>{
	sessionStorage.setItem("sheetId",item.sheetId)
	switch(item.serviceStatus){
		case 1:router.push({"path":"/recordDetail","query":{"type":"detail",'upType':'up2','serviceStatus':item.serviceStatus}});
			   break;
		case 2:
		case 4:
			router.push({"path":"/recordDetail","query":{"type":"detail",'serviceStatus':item.serviceStatus}});
			break;
		case 3:router.push("/ewm")
			   break;
	}
}
</script>
<style scoped>
#record{
	min-height: calc(100vh - 7.73vw);
	background: #FAFAFA;
	overflow: hidden;
	padding-bottom: 7.73vw;
}
.dataNull{
	display: block;
	margin: auto;
	width: 26.67vw;
}
.dataNullInfo{
	margin-top: 28vh;
}
.dataNullInfo span{
	display: block;
	color: #B5B5B5;
	font-size: 3.47vw;
	text-align: center;
	margin-top: 2vw;
}
.top{
	display: block;
	width: 100%;
}
.tab{
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 12vw;
	border-bottom: #009F4F solid 0.26vw;
	box-shadow: rgba(0, 0, 0, 0.05) 0 2vw 1vw;
}
.tab li{
	width: 43.87vw;
	height: 7.6vw;
	text-align: center;
	line-height: 7.6vw;
	background: #E5F5ED;
	border:#009F4F solid 0.13vw;
	border-radius: 2vw;
	font-size: 3.73vw;
	color: #000;
	font-weight: bold;
}
.tab li.current{
	background: #009F4F;
	color: #fff;
}
.recordList:before{
	display: block;
	content: "";
	width: 0.27vw;
	height: 21.73vw;
	background:#009F4F;
	position: absolute;
	top:50%;
	margin-top: -10.865vw;
}
.recordList.state1:before{
	background: #FF0000;
}
.recordList.state1 .progressNode{
	color:#FF0000;
}
.recordList.state2:before{
	background: #B5B5B5;
}
.recordList.state2 .progressNode{
	color:#B5B5B5;
}
.recordList.state3:before{
	background: #009F4F;
}
.recordList.state3 .progressNode{
	color:#009F4F;
}
.recordList{
	width: 92vw;
	margin: auto;
	background: #FFFFFF;
	border: 0.13vw solid #E5E5E5;
	box-shadow: 0 1vw 0 0 rgba(0, 0, 0, 0.05);
	border-radius: 2vw;
	margin-top: 7.73vw;
	position: relative;
}
.recordList ul{
	width:83.6vw;
	margin: auto;
}
.recordList li{
	padding: 3vw 0;
	border-bottom: #DADADA dashed 1px;
	position: relative;
	font-size: 3.47vw;
}

.outCase{
	position: absolute;
	width: 14.8vw;
	height: 5.2vw;
	display: block;
	text-align: center;
	line-height: 5.2vw;
	font-size: 2.93vw;
	color: #fff;
	background: #F39800;
	border-radius: 1.33vw;
	right: 4vw;
	top:2.5vw;
}
.recordList li:last-child{
	border-bottom: none;
}
.recordList li .title{
	color: #666666;
	font-size: 3.2vw;
}
.recordList li .detail.name{
	color: #000;
	font-size: 3.73vw;
}
.progressNode{
	display: block;
	position: absolute;
	font-size: 3.2vw;
	color: #000;
	font-weight: bold;
	background: url(../assets/images/right2.png) no-repeat right center;
	background-size: 1.73vw 2.53vw;
	padding-right: 3vw;
	bottom: 3.5vw;
	right: 4.8vw;
}
.qa{
	width: 8.67vw;
	display: block;
	position: fixed;
	right: 3.6vw;
	bottom: 5.73vw;
}
</style>