import request from '../../../api/request';
import {Module} from 'vuex'
import {RootState,AllRootState} from '../../interface'
import {Recordstate} from "./interface"
const recordModule:Module<Recordstate,RootState>={
  state: {
    progressNode:[
      {text:"服务结束",id:4},
      {text:"服务中",id:3},
      {text:"审核中",id:2},
      {text:"待补全材料",id:1}
    ]
  },
  actions: {
    advanceRecord:({commit},type)=>{
      return new Promise((resolve, reject)=>{
        request.get(`/api/v1/healthy-future/get-records/${type}`).then(res =>{
            resolve(res)
        }).catch(res=>{
          reject(res)
        })
      })
    },
    cancelApply:({commit},sheetId)=>{
      return new Promise((resolve)=>{
        request.get(`/api/v1/healthy-future/cancel/${sheetId}`).then(res =>{
            resolve(res)
        })
      })
    },
   
  }
}

export default recordModule

  