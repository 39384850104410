<template>
    <!--全能爱无忧愈健未来首页-->
    <div class="top">私家肿瘤管理</div>
    <!--服务内容-->
    <div class="detailBox">
      <div class="title">服务内容</div>
      <div class="con conTit">
        <p>服务名称</p>
        <p>服务内容</p>
        <p>次数</p>
      </div>
      <div class="con" v-for="item in serviceCon" :key="item.name">
        <p v-html="item.name"></p>
        <p>{{ item.content }}</p>
        <p>{{ item.num }}</p>
      </div>
    </div>
    <!--服务流程-->
    <div class="detailBox">
      <div class="title">服务流程</div>
      <ul class="list">
        <li>01. 会员点击“使用服务”，申请服务</li>
        <li>02. 会员填写基本信息和上传就诊资料并提交进行预约</li>
        <li>03. 医生团队进行资料审核</li>
        <li>04. 审核通过后，医生联系会员提供服务</li>
        <li>05. 服务结束</li>
      </ul>
    </div>
    <div class="agreeBox">
      <span class="radioBtn" @click="checkFlag=!checkFlag" :class="{'active': checkFlag}"></span>
      <span @click="check" :class="{ check: true, checked: checkFlag }"
        >我已阅读并了解</span
      >
      <a href="javascript:void(0)" @click="jumpWord">《全能爱无忧告知书》</a>
    </div>
    <div class="bntBox">
        <div class="signBtn b_l" @click="goRecord">我的订单</div>
        <div class="signBtn b_r"  @click="getService" :class="{'disabled': !checkFlag}">使用服务</div>
    </div>
    <Alert v-if="alertFlag" :alertData="alertData" @sure="alertSure"></Alert>
  </template>
  <script setup lang="ts">
  import { ref, reactive } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { AlertData } from "../components/interface/Alert-d";
  import waringImg from "../assets/images/waring.png";
  const router = useRouter();
  const store = useStore();
  let queryData = {
    policyNo: router.currentRoute.value.query.policyNo,
    userName: router.currentRoute.value.query.userName,
  };
  store.commit("upQuery", queryData);
  sessionStorage.setItem("upQuery", JSON.stringify(queryData));
  //爱无忧渠道号
  const requirementSource =
    router.currentRoute.value.query.requirementSource || "";
  sessionStorage.setItem("requirementSource", requirementSource.toString());
  const alertData = reactive<AlertData>({
    alertTitle: "温馨提示",
    alertContent: "您在服务等待期，不能使用服务",
    bntText: "我知道了",
    pwidth: 525,
    alertType: "alert",
    icon: waringImg,
  });
  const alertFlag = ref<boolean>(false);
  const checkFlag = ref(false);
  const check = () => {
    checkFlag.value = !checkFlag.value;
  };
  
  const serviceCon = ref([
    {
      name: "主动患者管理",
      content:
        "肿瘤的术后多专科复诊复查指导，建立患者健康档案，全面监控关键指标变化趋势，指导用药，辅助放化疗方案的实施。",
      num: "术后6个月风险窗口期不限次",
    },
    {
      name: "肿瘤症状管理",
      content:
        "针对放化疗期间的疼痛、失眠、抑郁、恶心呕吐便秘等肿瘤症状进行主动干预，全面提升患者生活质量。",
      num: "术后6个月风险窗口期不限次",
    },
    {
      name: "二次入院<br/>风险管理",
      content: "对术后合并症、肿瘤复发、转移等提供风险评估和就医指导。",
      num: "术后6个月风险窗口期不限次",
    },
    {
      name: "中医药调理",
      content:
        "互联网医院中医肿瘤科医师在线提供中药处方，促进术后康复，干预复发风险。",
      num: "术后6个月风险窗口期不限次",
    },
  ]);
  const jumpWord = () => {
    router.push('/privacyAWY')
  };
  const subFlag = ref(true);
  const getService = () => {
    if (!checkFlag.value) return;
    getServiceInfo()    
  };
  const getServiceInfo=()=>{
      if(!subFlag.value)return;
      subFlag.value=false;
      
      store.dispatch('useService').then(res =>{
          sessionStorage.setItem("userInfo",JSON.stringify(res.data));
          router.push({"path":'/apply',"query":{"type":"frHome","channel":'awy'}});
          subFlag.value=true;
      }).catch(()=>{
          subFlag.value=true
      })
  }
  const goRecord = () => {
        let queryData = {
          policyNo: router.currentRoute.value.query.policyNo,
          userName: router.currentRoute.value.query.userName,
        };
        store.commit("upQuery", queryData);
        sessionStorage.setItem("upQuery", JSON.stringify(queryData));
        router.push("/recordList");
  };
  
  const alertSure = () => {
    alertFlag.value = false;
  };
  </script>
  <style scoped>
  .top {
    height: 34.67vw;
    font-size: 6.93vw;
    font-weight: 900;
    color: #fff;
    line-height: 34.67vw;
    text-indent: 6vw;
    text-shadow: 0px 5px 0px rgba(7, 0, 2, 0.15);
    background: url(../assets/images/weiyi/aiwuyouBanner.png) no-repeat;
    background-size: 100%;
  }
  .detailBox {
    margin-top: 2.4vw;
    padding: 4vw 5.33vw 4.67vw;
    background: #fff;
  }
  .detailBox .title {
    display: flex;
    align-items: center;
    margin-bottom: 2.67vw;
    font-size: 4.27vw;
    font-weight: 700;
    color: #000;
  }
  .detailBox .title::before {
    content: "";
    width: 1.07vw;
    height: 4.67vw;
    margin-right: 2.4vw;
    background: #628bf5;
    border-radius: 4px;
  }
  .detailBox .con {
    display: flex;
    align-items: center;
    padding: 2.67vw 0;
    border: 1px solid #d8d8d8;
    border-top: none;
  }
  .detailBox .con p {
    font-size: 3.2vw;
    color: #666;
    text-align: center;
  }
  .detailBox .con p:first-child {
    width: 22.67vw;
    color: #000;
  }
  .detailBox .con p:nth-child(2) {
    flex: 1;
    padding: 0 2.67vw;
    text-align: left;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
  .detailBox .con p:last-child {
    width: 17vw;
  }
  .detailBox .conTit {
    height: 7.33vw;
    padding: 0;
    background: #628bf5;
    border: none;
  }
  .detailBox .conTit p {
    font-size: 3.47vw;
    font-weight: 700;
    color: #fff;
  }
  .detailBox .conTit p:first-child {
    border-right: 1px solid #fff;
    color: #fff;
  }
  .detailBox .conTit p:nth-child(2) {
    text-align: center;
    border-left: none;
  }
  .detailBox .list {
    padding: 3.33vw 3.33vw 5.33vw;
    font-size: 3.2vw;
    color: #666;
    line-height: 6.67vw;
    border: 1px solid #d8d8d8;
  }
  .agreeBox {
    display: flex;
    align-items: center;
    width: 76vw;
    line-height: 6vw;
    margin: 5.8vw auto 5.33vw auto;
  }
  .agreeBox span,
  .agreeBox a {
    display: block;
    font-size: 3.47vw;
  }
  .agreeBox span {
    color: #666;
  }
  .agreeBox a {
    color: #000;
    text-decoration: underline;
  }
  .bntBox {
    width: 91vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 9.37vw;
  }
  .bntBox span {
    display: block;
    width: 43.2vw;
    height: 9.87vw;
    font-size: 4.27vw;
    color: #fff;
    line-height: 9.87vw;
    text-align: center;
    background: linear-gradient(0deg, #b01f24, #e5575c);
    box-shadow: 0px 3px 0px 0px rgba(7, 0, 2, 0.08);
    border-radius: 1.33vw;
  }
  .bntBox span.getService.disabled {
    opacity: 0.5;
  }
.radioBtn{
    width: 3.87vw;
    height: 3.87vw;
    border: 0.4vw solid #628BF6;
    border-radius: 50%;
    position: relative;
    margin-right: 2.6vw;
}
.radioBtn.active::after{
    content:'';
    width: 2.47vw;
    height: 2.47vw;
    background-color: #628BF6;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 0.6vw;
    left: 0.7vw;
}
.signBtn{
    width: 42vw;
    height: 9.86vw;
    border-radius: 1.3vw;
    color: #fff;
    font-size: 4.27vw;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signBtn.disabled{
    opacity: 0.4;
}
.signBtn.b_l{
    background-image: linear-gradient(to top, #F44848, #F5C8C8);
    background-color: #F44848;
}
.signBtn.b_r{
    background-image: linear-gradient(to top, #4875F4, #C8D5F5);
    background-color: #4875F4;
    margin-left: 5.3vw;
}
  </style>
  