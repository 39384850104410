import request from '../../../api/request';
import { Module} from 'vuex'
import {RootState} from '../../interface'

const HomeModule:Module<any,RootState>={
  state: {
  },
  actions: {
    useService : () => {
      return new Promise((resolve, reject)=>{
          request.get('/api/v1/healthy-future/start-service').then(res =>{
              resolve(res)
          }).catch(res=>{
              reject(res)
          })
      })
    }
   
  }

}

export default HomeModule

  