<template>
  <div class="bg">
     <div class="cardBox">
       <span class="title">{{type}}</span>
      <div v-if="type==='上传身份证'" class="">
        <p class="upBox">
          <input @change="upImg($event,'idcardFrontBase64')" type="file" accept="image/*">
          <img alt="" :src="upData.idcardFrontBase64===''?idcardUp1:upData.idcardFrontBase64"/>
        </p>
        <p class="upBox">
          <input @change="upImg($event,'idcardBackBase64')" type="file" accept="image/*">
          <img alt="" :src="upData.idcardBackBase64===''?idcardUp2:upData.idcardBackBase64"/>
        </p>
       </div>
       <div v-if="type==='上传居民户口簿'" class="">
       <p class="upBox">
         <input @change="upImg($event,'huk')" type="file" accept="image/*">
        <img alt="" :src="upData.huk===''?huk:upData.huk"/>
       </p>
       </div>
       <div class="bntBox">
         <span @click="cancel" class="cancel">取消</span>
         <span @click="sure" :class="{sure:true,dis:((upData.idcardFrontBase64===''||upData.idcardBackBase64==='')&&type==='上传身份证')||(type==='上传居民户口簿'&&upData.huk==='')}">确认</span>
       </div>
     </div>
     <alert v-if="alertFlag" :alertData="alertData" @sure="alertSure"></alert>
  </div>
</template>
<script type="text/babel" lang="ts" setup>
import {ref,reactive } from "vue";
import { AlertData } from "./interface/Alert-d";
import { ImgUpItem,ImgShowItem } from "./interface/imgUp-d";
import { CardData } from "./interface/infoItem-d";
import idcardUp1 from "../assets/images/idcardUp1.png";
import idcardUp2 from "../assets/images/idcardUp2.png";
import huk from "../assets/images/huk.png"
import store from "../store";
import AlertFn from '../utils/alert';
const {alertData,upData,upDataFile}=reactive({
        upData:<any>{
          "idcardFrontBase64":"",
          "idcardBackBase64":"",
          "huk":""
        },
        upDataFile:<any>{},
        alertData:<AlertData>{
          alertTitle:"温馨提示",
          alertContent: "111",
          bntText:"我知道了",
          pwidth:525,
          alertType:"alert",
          icon:"src/assets/images/waring.png"
        }
        
})
const props = defineProps<{
      type:string
}>()
const alertFlag=ref(false)
const upImg=(e:any,type:string)=>{
  const file=e.target.files[0];
    if (file.type.indexOf('image') == -1) {
      alertData.alertContent='请选择图片文件';
      alertFlag.value=true;
      return;
    }else{
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (res)=> {
        if(res.target){
          let result=res.target.result;
          upData[type]=result
          upDataFile[type]=file
        }
      }
  }
}
const alertSure=()=>{
  alertFlag.value=false
}
const emit = defineEmits<{
	(e: 'sure',v:CardData): void 
  (e: 'cancel'): void
}>()
let subFlag=ref<boolean>(true);
const sure=()=>{
  if(((upData.idcardFrontBase64===''||upData.idcardBackBase64==='')&&props.type==='上传身份证')||(props.type==='上传居民户口簿'&&upData.huk==='')){
    return;
  }
    const params=new FormData()
    if(props.type==='上传身份证'){
        params.append("file",upDataFile['idcardFrontBase64']);
        params.append("extraFile",upDataFile['idcardBackBase64']);
        params.append("materialType",<any>1);
        
    }else if(props.type==='上传居民户口簿'){
        params.append("file",upDataFile['huk']);
        params.append("materialType",<any>3);
    }
  
    if(!subFlag.value)return;
    subFlag.value=false
    params.append("sheetId",<any>sessionStorage.getItem('sheetId'));
    store.dispatch("upImg",params).then(res=>{
      emit("sure",upDataFile)
      subFlag.value=true;
    }).catch(res=>{
      if(res.code===400007006){
        AlertFn(res.message)
      }
      subFlag.value=true;
    })
  
}
const cancel=()=>{
  emit("cancel")
}
</script>
<style type="text/css" scoped>
  .bg{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999
  }
  img{
    display: block;
    /* width:70.67vw; */
    height: 44vw;
    margin:6.67vw auto 0 auto;
  }
  .cardBox{
    width: 88.67vw;
    /* height: 137.6vw; */
    margin: auto;
    background: #fff;
    border-radius: 1vw;
    overflow: hidden;
  }
  .title{
    font-size: 4.27vw;
    color: #000;
    text-align: center;
    display: block;
    margin: 9.3vw 0 2.3vw 0;
  }
  .bntBox{
    width: 70.67vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 7.3vw 0 5.47vw 0;
  }
  .bntBox span{
    display: flex;
    width: 29.33vw;
    height: 10.6vw;
    background: linear-gradient(180deg, #FF7173, #FF7173, #FF888A, #FF7173);
    border: 0.27vw solid #FFFFFF;
    box-shadow: 0 1vw 0 0vw rgba(0, 0, 0, 0.05);
    border-radius: 3vw;
    font-size: 4.27vw;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #fff;
    letter-spacing: 3vw;
    text-indent: 3vw;
  }
   .bntBox span.sure{
     background: linear-gradient(180deg, #88FFC3, #009F4F);
   }
   .bntBox span.sure.dis{
     background: linear-gradient(180deg,  #ececec, #d3d3d3);
   }
   .upBox{
     position: relative;
   }
   .upBox input[type='file']{
     height: 100%;
     width: 100%;
     position: absolute;
     left: 0;
     top:0;
     opacity: 0;
   }
</style>