<template>
	<div id="agreement">
		<div class="">
			<p class="bold top">远盟卓康服务平台儿童隐私保护政策</p>
		</div>
		<span class="title">本隐私政策内容列表：</span>
		<span class="title mu">一、总则</span>
		<span class="title mu">二、我们收集儿童哪些个人信息</span>
		<span class="title mu">三、我们如何使用儿童个人信息</span>
		<span class="title mu">四、共享、转移、披露儿童个人信息的情形</span>
		<span class="title mu">五、存储儿童个人信息</span>
		<span class="title mu">六、管理儿童个人信息</span>
		<span class="title mu">七、访问儿童个人信息</span>
		<span class="title mu">八、更正儿童个人信息</span>
		<span class="title mu">九、删除儿童个人信息</span>
		<span class="title mu">十、本隐私政策的适用</span>
		<span class="title mu">十一、如何联系我们</span>
		<p class="right" style="margin-top: 8vw;">更新及生效日期：2022年5月23日<br/>版本：2022年5月</p>
		<span class="title" style="text-align:center">前言</span>
		<p>远盟卓康医院管理有限公司（以下简称：<span class="bold">“远盟卓康”</span>）致力于保护使用我们产品或服务的儿童的个人信息。《远盟卓康服务平台儿童隐私保护政策》（以下简称“本政策”）和《远盟卓康服务平台用户隐私政策》旨在向监护人（以下简称“您”）和儿童说明我们如何收集、使用、存储和处理儿童的个人信息，以及我们为您与儿童提供的访问、更正、删除和保护这些信息的方式。除另有约定外，本政策所用术语和缩略词与《远盟卓康服务平台用户协议》、《远盟卓康服务平台用户隐私政策》中的术语和缩略词具有相同的涵义，<span class="bold">特别提示：本政策适用于不满14周岁的儿童个人信息收集政策。</span></p>
		<span class="title">儿童监护人特别说明：</span>
		<p>如果您是儿童用户的监护人，请您仔细阅读和选择是否同意本政策。我们希望请您与我们共同保护儿童的个人信息，教育引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经您同意的情况下，不向任何网络产品和服务提供者提供任何个人信息。</p>
		<span class="title">儿童特别说明：</span>
		<p>如果你是14周岁以下的儿童，你需要和你的监护人一起仔细阅读本政策，并在征得你的监护人同意后，使用我们的产品、服务或向我们提供信息。</p>
		<p class="bold">请您在仔细阅读、充分理解《远盟卓康服务平台儿童隐私保护政策》、具体产品的隐私保护政策（如有）和本政策后，选择是否同意相关隐私政策以及是否同意您和您监护的儿童使用我们的产品或服务。如果您不同意相关隐私政策和本政策的内容或不同意提供服务所必要的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。您点击“同意”即表示您同意我们按照《远盟卓康服务平台儿童隐私保护政策》、具体产品的隐私保护政策（如有）和本政策的约定收集、使用和处理您和您监护的儿童的信息。</p>
		<p>请您理解，本政策仅适用于我们专门面向14周岁以下的儿童的产品或服务、具有专门面向14周岁以下儿童的功能板块的产品或服务，或者需要用户输入<span class="bold">姓名或昵称、身份证信息、生日、年龄信息及相关能识别用户年龄的产品或服务。针对不能识别或不主动识别用户年龄的产品或服务，适用《远盟卓康服务平台儿童隐私保护政策》和具体产品的隐私保护政策（如有）。我们提供的产品和服务中可能包括第三方提供的产品或服务，您也可能通过我们产品（如公众号）登录和使用第三方产品和服务。请您注意，该第三方可能收集、使用或处理儿童的相关信息，需要由该等第三方另行获得您的同意。我们建议您让儿童在向第三方授权收集处理其任何个人信息之前征求您的同意。</span></p>
		<p class="bold">我们提醒您，本政策中与您和您监护的儿童的权益（可能）密切相关的重要条款，我们已采用加粗字体来特别提醒您，请您重点查阅。</p>
		<p class="bold">如您在阅读本政策过程中有任何疑问或权利请求等个人信息相关事宜的，我们为您提供了专门的反馈渠道，具体参见本政策“<a class="link" @click="anchor('content')" href='javascript:void(0)'>如何联系我们</a>”章节，我们会尽快为您作出解答。</p>
		<span class="title">一、总则</span>
		<p>
			远盟卓康医院管理有限公司（注册地址：河北省秦皇岛市北戴河新区北戴河新大道西侧文博街北侧北戴河生命科学园6#楼，以下简称“<span class="bold">我们</span>”）系远盟卓康服务平台“愈健未来”公众号及小程序的运营者。我们非常重视保护用户（以下简称“<span class="bold">您</span>”）的个人信息和隐私。当您使用我们提供的救援及相关服务（以下或称“<span class="bold">服务</span>”）时，我们会收集、使用、保存、共享您的相关个人信息。为呈现我们处理您个人信息的情况，我们特制定《远盟卓康服务平台儿童隐私保护政策》（以下简称“<span class="bold">本隐私政策</span>”），并承诺严格按照本隐私政策处理您的个人信息。
		</p>
		<span class="title">二、我们收集儿童哪些个人信息</span>
		<p>远盟卓康服务平台严格遵守法律法规的规定及与用户的约定，根据用户选择产品或服务的具体情况，收集您和您监护的儿童的以下个人信息。</p>
		<p class="bold">针对我们专门面向14周岁以下的儿童的产品和服务或产品和服务的部分功能板块，或需要用户输入昵称、性别、生日信息，我们会要求用户填写上述信息，以识别该用户是否为6-14周岁的儿童，当儿童年龄满足后才允许监护人继续使用相关产品或服务。</p>
		<p>在儿童使用产品或服务过程中，我们会收集相关信息，具体请查阅《远盟卓康服务平台儿童隐私保护政策》的<span class="bold">“我们如何使用儿童个人信息”一节，</span>详细了解我们收集的其他儿童个人信息。</p>
		<p>如我们需要超出上述收集范围收集您或您监护的儿童的个人信息，我们将再次告知您，并征得您的同意。</p>
		<span class="title">三、我们如何使用儿童个人信息</span>
		<p>我们严格遵守法律法规的规定和与用户的约定，将收集的您和您所监护的儿童的信息用于以下用途：</p>
		<span class="title">1、验证儿童年龄</span>
		<span class="title">2、用于优化产品或服务、安全保障、服务调查目的</span>
		<p>如果我们需要超出上述用途使用您或您监护的儿童的个人信息，我们将再次告知您，并征得您的同意。</p>
		<span class="title">四、共享、转移、披露儿童个人信息的情形</span>
		<p>我们遵照法律法规的规定，严格限制共享、转移、披露儿童个人信息的情形。</p>
		<p>1、一般情况下，远盟不会向第三方分享儿童的个人信息。我们仅在监护人事先同意的情况下，才可能与第三方分享儿童的个人信息。</p>
		<p>2、仅为实现外部处理的目的，我们可能会与第三方合作伙伴（第三方服务供应商、承包商、代理、应用开发者等，例如，代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置服务的地图服务供应商）分享儿童的个人信息，让他们按照我们的说明、隐私政策以及相关的保密和安全政策来为我们处理上述信息。</p>
		<p>3、如我们委托第三方处理儿童个人信息的，我们会对受委托方及委托行为等进行安全评估，签署委托协议，要求受委托方的处理行为不得超出我们的授权范围，我们委托第三方处理儿童个人信息主要用于以下用途：</p>
		<p>（1）、向儿童提供我们的服务；</p>
		<p>（2）、实现“我们如何使用这些信息”部分所述目的；</p>
		<p>（3）、履行我们在<span class="bold">《远盟卓康服务平台用户隐私政策》</span>中的义务和行使我们的权利；</p>
		<p>（4）、理解、维护和改善我们的服务。</p>
		<p>如我们与上述第三方分享您和儿童的信息，我们将进行适当的安全评估并采用合法加密、匿名化、去标识化处理等手段保障您和儿童的信息安全。</p>
		<p>4、随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您和儿童的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本政策所要求的标准继续保护或要求新的管理者继续保护您和儿童的个人信息。</p>
		<p>5、我们可能会将所收集到的儿童信息在进行匿名化处理后用于大数据分析，如形成不包含任何个人信息的洞察报告等。</p>
		<p>6、我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</p>
		<p>7、一般情况下，我们禁止对外披露儿童个人信息，但我们可能基于以下目的披露儿童的个人信息：</p>
		<p>（1）、遵守适用的法律法规等有关规定；</p>
		<p>（2）、遵守法院判决、裁定或其他法律程序的规定；</p>
		<p>（3）、遵守相关政府机关或其他法定授权组织的要求；</p>
		<p>（4）、我们有理由确信需要遵守法律法规等有关规定；</p>
		<span class="title">五、存储儿童个人信息</span>
		<p>1、远盟卓康会采取传输加密等技术措施存储儿童个人信息，确保信息安全。远盟卓康存储儿童个人信息，不会超过实现其收集、使用目的所必需的期限。具体请查阅《远盟卓康服务平台用户隐私政策》“我们如何保护您的个人信息”章节，详细了解我们如何存储和保护儿童个人信息。</p>
		<p>2、当我们的产品或服务发生停止运营的情形或存储时间如超出法律的允许范围外，我们将采取例如，推送通知、公告等形式通知您和儿童，并删除或匿名化处理您和儿童的个人信息。</p>
		<span class="title">六、管理儿童个人信息</span>
		<p>在儿童用户使用远盟卓康产品或服务期间，为了您和儿童可以更加便捷地访问、更正、删除相关个人信息，我们在产品设计中为您提供了相应的操作设置，您也可以通过我们提供的联系方式，来反馈和咨询相关信息。我们会在核实您反馈的问题后及时与您联系。请您理解，在核实过程中，我们可能需要您提供儿童姓名、儿童身份证信息、医疗信息相关的个人信息，以便在完成身份验证和问题核实后继续响应您的需求。</p>
		<span class="title">七、访问儿童个人信息</span>
		<p>您和您监护的儿童可以查看儿童用户在使用我们产品或服务中提供或产生的个人信息，包括但不限于姓名、主动发布的身份证件号码、医疗信息及使用的服务等。</p>
		<span class="title">八、更正儿童个人信息</span>
		<p>当您和您监护的儿童发现我们收集、使用和处理的儿童个人信息有错误时，可以联系我们进行更正。我们会在完成身份验证和核实问题后，及时采取措施予以更正。</p>
		<span class="title">九、删除儿童个人信息</span>
		<p>1、如存在以下情况，您和您监护的儿童可以请求我们删除收集、使用和处理的儿童个人信息。我们会在完成身份验证和核实问题后，及时采取措施予以删除。</p>
		<p>2、若我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、转移、披露儿童个人信息；</p>
		<p>3、若我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息；</p>
		<p>4、您撤回同意；</p>
		<p>5、您或您监护的儿童通过注销等方式终止使用产品或者服务的。</p>
		<p>但请注意，若您和被监护儿童要求我们删除特定儿童个人信息，可能导致该儿童用户无法继续使用我们的产品和服务或产品和服务中的某些具体业务功能。</p>
		<p>如您发现在未事先征得您同意的情况下收集了您儿童的个人信息，请及时联系我们，我们会尽快删除或匿名化处理相关数据。</p>
		<span class="title">十、本隐私政策的适用</span>
		<p>请您理解，如本隐私政策未规定之处，参照适用《远盟卓康服务平台用户隐私政策》。</p>
		<p class="bold">在适用本隐私政策的情况下，如与《远盟卓康服务凭条用户隐私政策》条款规定存在不一致的，儿童个人信息处理规则以本隐私政策为准。</p>
		<span id="content" class="title">十一、如何联系我们</span>
		<p>如果您对本隐私政策或儿童保护条款有任何问题或权利的实现，您均可以将您的书面疑问、意见或建议通过以下地址寄至客服部门：</p>
		<p class="bold">名称：远盟普惠平台客服中心</p>
		<p class="bold">地址：北京市朝阳区十八里店镇弘燕南一路富华弘燕大厦8002</p>
		<p>或您也可以通过以下电话与客服部门进行联系：</p>
		<p class="bold">联系电话：010-57071466</p>
		<p class="bold">联系邮箱：phcallcenter@healthlink.cn</p>
		<p>一般情况下，我们将在十五个工作日内回复，我们会及时尽到企业责任相应您的需求。</p>
	</div>
</template>
<script setup lang="ts">
const anchor=(id:string)=> {
 	document.getElementById(id)?.scrollIntoView({
		behavior: "smooth", // 平滑过渡
		block: "start" // 上边框与视窗顶部平齐。默认值
	});
}
</script>
<style scoped>
</style>