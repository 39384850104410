<template>
    <div v-cloak id="uploading">
		<div class="top">
			<span class="modelTitle">材料上传须知</span>
			<p>1、请根据下方要求上传相应材料图片</p>
			<p>2、材料图片需确保边框完整、文字清晰、证明加盖公章且每张单证分开拍摄。</p>
			<p>3、请尽量提供完整资料，以便于医生更清晰的了解您的就医情况，为您提供更好的康复服务。</p>
			<p v-if="$router.currentRoute.value.query.type==='up2'">4、备注：{{remark}}</p>
		</div>
		<span :class="'state state'+serviceStatus" v-if="serviceStatus===1||serviceStatus==2">{{getState(store.state.recordModule.progressNode,serviceStatus,'getText')}}</span>
		<!-- <div>
			<div id="imgItemidCard" :class="{infoItem:true,idCard:true,error:(!imgList['1']||imgList['1'].length===0)&&subClickFalg}">
				<span class="modelTitle">身份证正反面<em v-if="$router.currentRoute.value.query.type!=='detail'">必传项</em></span>
				<div v-if="imgList['1']" class="imgBox">
					<img @click="imgClick(imgList['1'][0].uploadUrl)" :src="imgList['1']&&imgList['1'][0].uploadUrl" alt="">
					<img @click="imgClick(imgList['2'][0].uploadUrl)" :src="imgList['2']&&imgList['2'][0].uploadUrl" alt="">
					<span class="del" v-if="(imgList['1'][0].reviewStatus!==1&&imgList['2'][0].reviewStatus!==1)&&$router.currentRoute.value.query.type!=='detail'" @click="delImg(imgList['1'][0].id+','+imgList['2'][0].id)">清空重传</span>
				</div>
				<img class="add" v-if="!imgList['1']&&$router.currentRoute.value.query.type!=='detail'"  @click="addCard('上传身份证')" src="../assets/images/cardAdd.png" alt=""/>
			</div>
			<div id="imgItemhk" :class="{infoItem:true,idCard:true,error:(!imgList['3']||imgList['3'].length===0)&&subClickFalg}" >
				<span class="modelTitle">居民户口簿<em v-if="$router.currentRoute.value.query.type!=='detail'">必传项</em></span>
				<div v-if="imgList['3']" class="imgBox">
					<img @click="imgClick(imgList['3'][0].uploadUrl)" :src="imgList['3']&&imgList['3'][0].uploadUrl" alt="">
					<img v-if="imgList['3'][0].reviewStatus!==1&&$router.currentRoute.value.query.type!=='detail'" class="close" @click="delImg(imgList['3'][0].id)" src="../assets/images/close.png" alt=""/>
				</div>
				<img class="add" v-if="!imgList['3']&&$router.currentRoute.value.query.type!=='detail'" @click="addCard('上传居民户口簿')" src="../assets/images/cardAdd.png" alt=""/>
				<div v-if="$router.currentRoute.value.query.type!=='detail'" class="checkExample">
					<img alt="" @click="imgBig('exampleHk')" :src="exampleHk"/>
					<span>示例，点击查看</span>
				</div>
			</div>
		</div> -->
		<div v-for="(item,index) in upData" >
			<div>	
				<div :id="'imgItem' + index" :class="{infoItem:true,error:(!imgList[item.materialType]||imgList[item.materialType].length===0)&&subClickFalg&&item.require}">
					<span class="modelTitle" >{{item.title}}<em v-if="item.require&&pageData.fromRouter!=='recordDetail'&&$router.currentRoute.value.query.type!=='detail'">必传项</em></span>
					<p v-if="item.tips" class="tips">{{item.tips}}</p>
					<img-up 
						@uplodad="imgUpLoad" 
						@imgClick="imgClick" 
						@del="imgDel" 
						@choose-sucess="imgChooseS"
						@choose-fail="imgChooseF"
						:limit="item.limit" 
						:page-type="$router.currentRoute.value.query.type==='detail'?'detail':'input'" :materialType="item.materialType" 
						:imgList="imgList[item.materialType]"
					></img-up>
					<div v-if="$router.currentRoute.value.query.type!=='detail'" :class="{checkExample:true,tipsShow:item.tips}">
						<img v-if="item.showImg!==''" alt="" @click="imgBig(index)" :src="item.showImg"/>
						<span v-if="item.showImg!==''" >示例，点击查看</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="imgFlag" class="imgShowBox">
			<div class="imgShow">
				<span class="title">{{imgShowTitle}}</span>
				<img :src="showImg" alt=""/>
				<span @click="closeImg" class="closeImg">关闭弹层</span>
			</div>
		</div>
	</div>
	<div v-if="$router.currentRoute.value.query.type!=='detail'" class="bntBox">
		
		<span class="pre"  @click="$router.push('/recordList')">保存</span>
		<span class="save" @click="save">提  交</span>
	</div>
	<div v-if="$router.currentRoute.value.query.type==='detail'" class="bntBox detail">
		<span class="save" @click="$router.push('/recordList')">返 回</span>
	</div>
	<alert v-if="alertFlag" :alertData="alertData" @sure="alertSure" @cancel="alertCancel"></alert>
	<div v-if="loadingFlag" class="loaddingBg">
		<img src="../assets/images/loading.gif" alt="">
	</div>
	<card-up v-if="upCardFlag" @sure="upCardSure" @cancel="upcardCancel" :type="upCardType"></card-up>
</template>
<script setup lang="ts">
import { ref ,reactive} from 'vue';
import {useStore} from 'vuex';
import router from '../router';
import { ImgShowItem } from '../components/interface/imgUp-d';
import { AlertData } from '../components/interface/Alert-d';
import { getState } from '../utils/index';
import waringImg from "../assets/images/waring.png"
import alertSuccessImg from "../assets/images/alertSuccess.png"
import exampleBA from "../assets/images/exampleBA.jpeg"
import exampleBL from "../assets/images/exampleBL.jpg"
import exampleXJ from "../assets/images/exampleXJ.jpg"
import exampleFL from "../assets/images/exampleFL.jpeg"
import exampleJC from "../assets/images/exampleJC.jpeg"
import exampleJL from "../assets/images/exampleJL.jpg"
import exampleSS from "../assets/images/exampleSS.jpeg"
import exampleHk from "../assets/images/exampleHk.png"
import { CardData } from "../components/interface/infoItem-d";
const serviceStatus=ref();
serviceStatus.value=parseInt(<any>router.currentRoute.value.query.serviceStatus)
const alertData=reactive<AlertData>({
	alertTitle:"温馨提示",
	alertContent: "111",
	bntText:"我知道了",
	pwidth:525,
	alertType:"alert",
	icon:waringImg
})
const loadingFlag=ref(false)
const pageData=reactive({
	nodeStatus:router.currentRoute.value.query.nodeStatus,
	fromRouter:router.forward.name
})
const alertFlag=ref(false)
const alertSure=()=>{
	alertFlag.value=false;
	if(alertData.alertTitle==="您的资料已提交"){
		router.push("/recordDetail")
	}
	if(alertData.alertContent==="是否确认删除图片"){
		store.dispatch("delImg",delId.value).then(res=>{
			getData();
		})
	}
	if(alertData.alertContent==="请确认上传资料无误，收到资料后，我们会尽快为您提供服务"){
		saveImg()
	}
}
const alertCancel=()=>{
	alertFlag.value=false
}
const store=useStore();
const imgList=ref<any>({});
const remark=ref();
const materialNum=ref()
const getData=()=>{
	const sheetId=sessionStorage.getItem('sheetId');
	store.dispatch("imageList",sheetId).then((res)=>{
		const list=res.data.list;
		materialNum.value=list.length;
		let newImgList:any={};
		list.map((it:ImgShowItem)=>{
			let key= it.materialType+"";
			if(!newImgList[key])newImgList[key]=[];
			newImgList[key]=[...newImgList[key],it]
		})
		imgList.value=newImgList;
		remark.value=res.data.remark;
	})
}
getData()
const imgUpLoad=(data:any)=>{
	if(!imgList.value[data.materialType]||imgList.value[data.materialType].lengeh===0){
		imgList.value[data.materialType]=[]
	}
	
	store.dispatch("wxUpImg",data.upData).then(resp=>{
		// imgList.value[data.materialType]=[...imgList.value[data.materialType],...resp.data.list]
		getData()
		loadingFlag.value=false;
     }).catch(res=>{
		 loadingFlag.value=false;
	 })
}
const imgChooseS=()=>{
	loadingFlag.value=true;
}
const imgChooseF=()=>{
	loadingFlag.value=false;
}
const upData=[
	{"title":"身份证正面","materialType":1,"require":true,"showImg":"","limit":1},
	{"title":"身份证反面","materialType":2,"require":true,"showImg":"","limit":1},
	{"title":"居民户口薄","materialType":3,"require":true,"showImg":exampleHk,"limit":1},
	{"title":"病案首页","materialType":4,"require":true,"showImg":exampleBA,"limit":5},
	{"title":"住院记录","materialType":5,"require":true,"showImg":exampleJL,"limit":5},
	{"title":"出院小结","materialType":6,"require":true,"showImg":exampleXJ,"limit":5},
	{"title":"病理报告","materialType":7,"require":false,"showImg":exampleBL,"limit":5},
	{"title":"手术记录","materialType":8,"require":false,"showImg":exampleSS,"limit":5},
	{"title":"放化疗记录","materialType":9,"require":false,"showImg":exampleFL,"limit":5},
	{"title":"检验检查报告","materialType":10,"require":false,"showImg":exampleJC,"limit":5,"tips":""},
	{"title":"其它资料","materialType":11,"require":false,"showImg":"","limit":5}
]
const materialImgList=ref<ImgShowItem[]>()
const showImg=ref("");
const imgFlag=ref(false);
const imgShowTitle=ref();
const imgBig=(index:any)=>{
	if(index==='exampleHk'){
		imgShowTitle.value="居民户口本"
		showImg.value=exampleHk;
	}else{
		imgShowTitle.value=upData[index].title
		showImg.value=upData[index].showImg;
		
	}
	imgFlag.value=true;
}
const imgClick=(url:string)=>{
	imgShowTitle.value=""
	showImg.value=url;
	imgFlag.value=true;
}
const closeImg=()=>{
	imgFlag.value=false;
}
const imgDel=()=>{
	getData();
}
const subClickFalg=ref(false);
const verigy=()=>{
	let verigyFlag=true;
	upData.map(it =>{
		if(it.require&&(!imgList.value[it.materialType]||imgList.value[it.materialType].length===0)){
			verigyFlag=false;
			
		}
	})
	return verigyFlag
}


const getIndex=()=>{
	let newIndex;
	if(!imgList.value['1']||imgList.value['1'].length===0){
		newIndex='idCard';
		return newIndex;
	}
	if(!imgList.value['3']||imgList.value['3'].length===0){
		newIndex='hk';
		return newIndex;
	}
	for ( const key  in  upData){
		if(upData[key].require&&(!imgList[upData[key].materialType]||imgList[upData[key].materialType].length===0)){
			newIndex =key;
			break;
		}
	}
	return newIndex;
}
const scrollFn=(div:string)=>{
	const node=document.getElementById(div);
	const top=<number>node?.offsetTop;
	 window.scrollTo(0, top-70)
}

const subFlag=ref(true)
 const save= ()=>{
	subClickFalg.value=true;
	if(getIndex()){
		let div="imgItem"+getIndex();
		scrollFn(div)
	}
	if(!verigy()) return;
	alertData.alertContent="请确认上传资料无误，收到资料后，我们会尽快为您提供服务"
	alertData.alertType="comfirm";
	alertData.bntText="确认";
	alertData.cancelBnt="返回"
	alertFlag.value=true;
	
}
const saveImg=()=>{
	if(!subFlag.value)return;
	subFlag.value=false;
	const param={
		sheetId:sessionStorage.getItem("sheetId"),
		materialNum:materialNum.value
	}
	store.dispatch("materialSubmit",param).then((res)=>{
		router.push('/recordList')
	})
}
//身份证，户口本上传
const upCardType=ref();
const upCardFlag=ref(false);
const addCard=(title:string)=>{
	upCardType.value=title;
	upCardFlag.value=true;
}
const upCardSure=(data:any)=>{
	getData();
	upCardFlag.value=false;
}
const upcardCancel=()=>{
	upCardFlag.value=false
}
//删除图片
const delId=ref();
const delImg=(id:string)=>{
    alertData.alertType="comfirm";
    alertData.alertTitle=""
    alertData.alertContent="是否确认删除图片";
    alertData.bntText="确定";
	alertData.cancelBnt="取消"
    alertFlag.value=true;
    delId.value=id;
}

</script>
<style scoped>
.tips{
	width: 76vw;
	font-size: 2.93vw;
	color: #FF7173;
	margin: auto;
	margin-bottom: 2vw;
}
.infoItem.idCard img{
	display: block;
	width: 29vw;
	height: 19.73vw;
	margin-right: 5vw;
}
.infoItem.idCard .checkExample img{
	height:15.87vw;
	width: 15.87vw;
}
.infoItem.idCard .imgBox{
	display: flex;
	width: 81.87vw;
	margin: auto;
	position: relative;
}
.infoItem.idCard .close{
	position: absolute;
	height: 5.33vw;
    left: 25vw;
	top:-1vw;
	width: 5.33vw;
	display: block;
}
.infoItem.idCard img.add{
	margin-left: 5vw;
}
.upBox{
	width: 65.73vw;
  	margin-left: 7.2vw;
}
.top{
	width: 94.37vw;
	margin: auto;
 }
.top p{
	 width:82vw;
	 margin: auto;
	 font-size: 3.2vw;
	 color: #666666;
	 margin-bottom: 3.5vw;
 }
 .top .modelTitle{
	 color: #F39800;
 }
.top .modelTitle:before{
	background:#F39800;
}
.infoItem{
	width: 94.67vw;
	background: #fff;
	border: 0.13vw solid #DDDDDD;
	box-shadow: 0vw 1vw 0vw 0vw rgba(0, 0, 0, 0.05);
	border-radius: 3vw;
	margin: auto;
	position: relative;
	min-height: 35.93vw;
	margin-top: 5.33vw;
}
.infoItem.error{
	border-color: #FF7173;
}
.modelTitle{
	width: 81.07vw;
	margin:4vw auto;
	display: block;
	font-size: 4vw;
	color: #000;
	font-weight: bold;
	position: relative;
	display: flex;
	align-items: center;
}
.modelTitle em{
	display: block;
	width: 10.8vw;
	border-radius: 1vw;
	background: #F39800;
	text-align: center;
	line-height:5.2vw;
	color: #fff;
	font-size: 2.93vw;
	font-weight: normal;
	font-style: normal;
	margin-left: 3.8vw;
}
.modelTitle:before{
	position: absolute;
	left: -4vw;
	top:0.8vw;
	display: block;
	content: "";
	height: 4vw;
	width: 1.33vw;
	border-radius: 5vw;
	background: #009F4F;
}
.checkExample{
	position: absolute;
	width: 23.27vw;
	right: 5.87vw;
	top:13.73vw;
}
.checkExample.tipsShow{
	top:18vw
}
.checkExample img{
	width: 15.87vw;
	height: 15.87vw;
	border-radius: 1vw;
	margin: auto;
	display: block;
}
.checkExample span{
	font-size: 2.63vw;
	color: #666;
	display: block;
	margin-top: 1vw;
}
.bntBox{
	display: flex;
	width: 94.67vw;
	margin: auto;
	justify-content: space-between;
	margin-top: 11.87vw;
}
.bntBox span{
	height: 11.93vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 4.27vw;
	font-weight: bold;
}
.bntBox span.pre{
	border: 0.27vw solid #009F4F;
	box-shadow: 0 1vw 0 0 rgba(0, 0, 0, 0.05);
	border-radius: 3vw;
	width: 36vw;
	color: #009F4F;
}
.bntBox span.save{
	width: 53.33vw;
	background: linear-gradient(180deg, #88FFC3, #009F4F);
	border: 0.27vw solid #FFFFFF;
	box-shadow: 0 1vw 0 0 rgba(0, 0, 0, 0.05);
	border-radius: 3vw;
	color: #fff;
}
.bntBox.detail span.save{
	width: 94.67vw;
}
.imgShowBox{
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	top:0;
	left: 0;
}
.imgShow{
	width: 88.67vw;
	background: #fff;
	border-radius: 1vw;
}
.imgShow img{
	display: block;
	max-width: 81.87vw;
	margin: auto;
	max-height: 80vh;
}
.imgShow .title{
	font-size: 4.27vw;
	display: block;
	margin: 6vw 0 5vw 0;
	color: #000;
	text-align: center;
}
.closeImg{
	display: block;
	margin: auto;
	text-align: center;
	color:#FF7173;
	text-decoration: underline;
	font-size: 3.73vw;
	padding: 4.2vw 0 4.57vw 0;
}
#imgItemidCard .del{
	line-height: 6vw;
    display: block;
    width: 17vw;
    background: linear-gradient(180deg, #FF7173, #FF7173, #FF888A, #FF7173);
    color: #fff;
    font-size: 2.93vw;
    text-align: center;
    height: 6vw;
    border-radius: 2vw;
}
#imgItemidCard .imgBox{
	align-items:center;
}
.state{
	display: block;
	position: absolute;
	height: 5.2vw;
	line-height: 5.2vw;
	padding: 0 2.27vw;
	border-radius: 1.33vw;
	font-size: 2.93vw;
	right: 5vw;
	top:0vw;
	
}
.state.state1{
	color: #FF0000;
	border:#FF0000 solid 1px;
	background: #FFE5E5;
}
.state.state2{
	background:#F39800;
	color: #fff;
}
#uploading{
	position: relative;
}
</style>