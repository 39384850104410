import axios from 'axios';
import {AxiosResponse} from './request-type';
import Alert from '../utils/alert';
import router from '../router';
let _baseURL="https://apiphtrads.healthlink.cn/";
if (window.location.host.indexOf('wpttest') != -1 || window.location.host.indexOf('172.') != -1 || window.location.host == ''|| window.location.host.indexOf('localhost') != -1||window.location.host.indexOf('hf-dev') != -1||window.location.host.indexOf('hf-test') != -1) {
	_baseURL="https://hbs-apitest.healthlink.cn/crm-standard-interface/";
}
let service = axios.create({
    baseURL: _baseURL,
    timeout: 20000
});


let token="";

const  getToken=()=>{
    const queryData=sessionStorage.getItem("upQuery");
    return  axios.get(_baseURL+"/api/v1/healthy-future/token",{
                headers:{
                    policyNo:queryData?JSON.parse(queryData).policyNo:"",
                    userName:queryData?JSON.parse(queryData).userName:"",
                }
            }).then(res=>{
                if(res.data.code===0){
                    token=res.data.data.token;
                }
                // else{
                //     router.push('/authFail')
                // }
                
            })
}
service.interceptors.request.use(
    async (config:AxiosResponse) => {
        await getToken();
        
        if (token) {  
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if(response&&(response.data.code===0)){
            return response.data;  
        }else if(response.data.code===400007016||response.data.code===400007010||response.data.code===400007011||response.data.code===400007012||response.data.code===400007013||response.data.code===400007014){
            Alert(response.data.message);
            throw response.data
        }else if(response.data.code===400007009||response.data.code===400007006){
            throw response.data
        }else{
            Alert("系统繁忙，请稍后再试")
            throw response.data
        }
    },
    error => {
        console.log(error)
        return Promise.reject();
        
    }
);

export default service;
