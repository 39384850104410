<template>
  <div
    v-cloak
    id="baseInfo"
    :class="{ detial: $router.currentRoute.value.query.type === 'detail' }"
  >
    <span
      :class="'state state' + serviceStatus"
      v-if="serviceStatus === 1 || serviceStatus == 2"
      >{{
        getState(
          store.state.recordModule.progressNode,
          serviceStatus,
          "getText"
        )
      }}</span
    >
    <div>
      <info-item
        class="itemItem"
        :info-model="dangerTypeInfo"
        @radio-change="radioChange($event, 'choose')"
        :sub-click="subClick"
        v-model="formData"
        v-if="$router.currentRoute.value.query.type !== 'detail'"
      ></info-item>
      <info-item
        class="itemItem"
        :sub-click="subClick"
        :infoModel="policyNoInfo"
        v-if="$router.currentRoute.value.query.type !== 'detail'&&$router.currentRoute.value.query.channel!=='awy'"
        v-model="formData"
      >
      </info-item>
      <info-item
        @input-blur="inputBlur"
        @radio-change="radioChange($event, 'choose')"
        class="itemItem"
        :infoModel="caseInfo"
        :sub-click="subClick"
        v-model="formData"
        @date-choose="dateChoose"
        @searchInput="hospitalSearch"
        @select-click="selectClick"
        @question="question"
        @searchInit="hospitalSearch"
      ></info-item>
    </div>
    <span class="save" @click="save">{{
      $router.currentRoute.value.query.type === "detail" ? "返回" : "下一步"
    }}</span>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        v-if="showPicker"
        title="请选择"
        :columns="selectData.columns"
        :visible-item-count="5"
        :defaultIndex="selectData.defaultIndex"
        @confirm="selectConfirm"
        @cancel="selectCancel"
      ></van-picker>
    </van-popup>
    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="defaultDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
    <Alert
      v-if="alertFlag"
      :alertData="alertData"
      @cancel="alertCancel"
      @sure="alertSure"
    ></Alert>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import {
  InfoModel,
  InputListItem,
  SearchData,
} from "../components/interface/infoItem-d";
import {
  idValidation,
  nameValidation,
  phoneValidation,
  IdCardGetInfo,
} from "../utils/index";
import alertFn from "../utils/alert";
import router from "../router";
import { AlertData } from "../components/interface/Alert-d";
import { getState } from "../utils/index";
import waringImg from "../assets/images/waring.png";
const store = useStore();

const minDate = ref(new Date("1900-01-01"));
const alertFlag = ref(false);
const alertData = reactive<AlertData>({
  alertTitle: "温馨提示",
  alertContent: "",
  bntText: "确定",
  pwidth: 525,
  alertType: "alert",
  icon: waringImg,
});
const selectData = reactive({
  columns: [],
  defaultIndex: 0,
});
const serviceStatus = ref();
serviceStatus.value = parseInt(
  <any>router.currentRoute.value.query.serviceStatus
);
const question = (item: InputListItem) => {
  let discript = "";
  switch (item.title) {
    case "上门服务地址":
      discript = "请填写患者康复居住地地址，用于后续提供上门康复服务";
      break;
    case "物流收货地址":
      discript = "请填写患者可收取快递地址，用于后续提供康复用品快递";
      break;
    case "病症":
      discript = "请填写诊断证明或出院诊断疾病名称";
      break;
  }

  alertData.alertType = "alert";
  alertData.alertContent = `<p style='font-size:3.73vw;line-height:6.67vw;color:#555;text-align: left;width:56.93vw;margin:auto'>${discript}</p>`;
  alertData.bntText = "我知道了";
  alertFlag.value = true;
};
const showPicker = ref(false);
const selectClick = (item: InputListItem) => {
  console.log(selectData.columns);
  showPicker.value = true;
  selectData.columns = item.columns;
  let default1 = formData.value[item.name];
  let default2 = "";
  if (item.name === "address") {
    let selectV = formData.value[item.name]
      ? formData.value[item.name].split("-")
      : 0;
    default1 = selectV === 0 ? 0 : selectV[0];
    default2 = selectV === 0 ? 0 : selectV[1];
  }

  selectData.columns.forEach((it: any, index: number) => {
    if (it.text === default1) {
      selectData.defaultIndex = index;
    }
    if (it.children) {
      it.children.forEach((itc: any, indexc: number) => {
        if (itc.text === default2) {
          it.defaultIndex = indexc;
        }
      });
    }
  });
};
const alertCancel = () => {
  alertFlag.value = false;
};

const alertSure = () => {
  if (alertData.alertType === "comfirm") {
    baseInfoSave();
  }
  alertFlag.value = false;
};
const show = ref(false);
const defaultDate = ref();
const dateChoose = (item: InputListItem) => {
  show.value = true;
  defaultDate.value =
    formData.value[item.name] && formData.value[item.name] != ""
      ? new Date(formData.value[item.name])
      : new Date();
};
const dateCancel = () => {
  caseInfo.inputList.forEach((it: InputListItem) => {
    if (formData.value.selectType === it.name) {
      it.errorJudge = true;
    }
  });
  show.value = false;
};
const policyNoInfo = {
  title: "保单信息",
  inputList: [
    {
      title: "保单号",
      type: "readOnly",
      require: true,
      name: "policyNo",
      vIF: true,
      plalceHolder: "请输入保单号",
    },
  ],
};
const dangerTypeInfo = {
  title: "出险类型",
  inputList: [
    {
      title: "出险类型",
      type: "radio",
      require: true,
      name: "riskType",
      radioArray: [
        { text: "疾病", id: 1 },
        { text: "意外", id: 2 },
      ],
      plalceHolder: "",
      vIF: true,
    },
  ],
};
const formData = ref<any>({
  riskType: "疾病",
});
const searchData = ref<SearchData[]>();
const caseInfo = reactive<InfoModel>({
  title: "案件基本信息",
  inputList: [
    {
      title: "姓名",
      type: "readOnly",
      require: true,
      name: "userName",
      plalceHolder: "请输入您的真实姓名",
      vIF: true,
      very: "name",
    },
    {
      title: "证件号码",
      type: "text",
      require: true,
      name: "identityNum",
      plalceHolder: "请输入证件号码",
      vIF: true,
      ageJudge: 2,
      errorMsg: "证件号码不能为空",
    },
    {
      title: "出生日期",
      type: "readOnly",
      require: false,
      name: "birthDate",
      plalceHolder: "请选择出生日期",
      vIF: true,
    },
    {
      title: "年龄",
      type: "readOnly",
      require: false,
      name: "age",
      plalceHolder: "请输入年龄",
      vIF: true,
    },
    {
      title: "监 护 人</br>姓名",
      type: "text",
      maxLength: 10,
      require: true,
      name: "guardianName",
      plalceHolder: "请输入监护人姓名",
      errorMsg: "监护人姓名不能为空",
      vIF: false,
      very: "name",
    },
    {
      title: "监护人身份证号",
      type: "text",
      require: true,
      name: "guardianIdentityNum",
      plalceHolder: "请输入监护人证件号",
      errorMsg: "监护人证件号不能为空",
      vIF: false,
      very: "idCard",
    },
    {
      title: "监护人联系方式",
      type: "text",
      require: true,
      name: "guardianTel",
      plalceHolder: "请输入监护人手机号",
      errorMsg: "监护人手机号不能为空",
      vIF: false,
      very: "phone",
    },
    // {"title":"监护人与被服务儿童关系","type":"text","require":true,"name":'guarderRelation','plalceHolder':"请输入监护人与被服务儿童的关系","vIF":false},
    {
      title: "联系方式",
      type: "text",
      require: true,
      name: "contactTel",
      plalceHolder: "请输入联系方式",
      vIF: true,
      very: "phone",
      errorMsg: "手机号码不能为空",
    },
    {
      title: "所在城市",
      type: "select",
      require: true,
      name: "address",
      plalceHolder: "请选择所在城市",
      vIF: true,
      errorMsg: "所在城市不能为空",
    },
    {
      title: "上门服务地址",
      type: "text",
      maxLength: 30,
      require: true,
      name: "serviceAddress",
      plalceHolder: "请输入详细地址",
      errorMsg: "上门服务地址不能为空",
      vIF: true,
      very: "inpatientNumber",
      question: true,
    },
    {
      title: "物流收货地址",
      type: "text",
      maxLength: 30,
      require: true,
      name: "shippingAddress",
      plalceHolder: "请输入物流收货地址",
      errorMsg: "物流收货地址不能为空",
      vIF: true,
      very: "inpatientNumber",
      question: true,
    },
    {
      title: "医院",
      type: "search",
      require: true,
      name: "hospitalName",
      plalceHolder: "请选择就医医院",
      vIF: true,
      errorMsg: "医院不能为空",
      searchData: searchData.value,
    },
    {
      title: "科室",
      type: "text",
      maxLength: 10,
      require: true,
      name: "deptName",
      plalceHolder: "请输入科室名称",
      vIF: true,
      errorMsg: "科室不能为空",
      very: "department",
    },
    {
      title: "病症",
      type: "text",
      maxLength: 15,
      require: true,
      name: "diseaseName",
      plalceHolder: "请输入病症",
      vIF: true,
      errorMsg: "病症不能为空",
      very: "inpatientNumber",
      question: true,
    },
    {
      title: "病情描述",
      maxLength: 100,
      type: "textarea",
      require: true,
      name: "illDescription",
      plalceHolder: "请输入疾病描述",
      errorMsg: "病情描述不能为空",
      vIF: true,
    },
  ],
});

store.dispatch("getCity").then((res) => {
  caseInfo.inputList.forEach((it: InputListItem) => {
    if (it.title === "所在城市") {
      it.columns = res.data;
    }
  });
});
const pageType = router.currentRoute.value.query.type;
const sheetId = sessionStorage.getItem("sheetId");
const getData = () => {
  store.dispatch("getInfo", sheetId).then((res) => {
    formData.value = res.data;
    policyNoInfo.inputList[0].require = false;
    formData.value.address = formData.value.city;
    caseInfo.inputList = [
      {
        title: "保单号",
        type: "readOnly",
        require: true,
        name: "policyNo",
        vIF: true,
        plalceHolder: "请输入保单号",
      },
      {
        title: "出险类型",
        type: "radio",
        require: true,
        name: "riskType",
        plalceHolder: "",
        vIF: true,
      },
      ...caseInfo.inputList,
    ];
    caseInfo.inputList.map((it: InputListItem) => {
      if (it.type === "certificateUp") {
        it.title =
          formData.value.idCradImageList.length > 1 ? "身份证" : "户口簿";
      }
      it.type = it.type === "certificateUp" ? "certificateUpShow" : "readOnly";
      it.tips = "";
      it.require = false;
      if (formData.value[it.name]) {
        it.vIF = true;
      }
    });
  });
};
if (pageType === "detail") {
  getData();
} else {
  const userInfo = sessionStorage.getItem("userInfo")
    ? JSON.parse(<string>sessionStorage.getItem("userInfo"))
    : undefined;
  if (userInfo) {
    formData.value = userInfo;
    formData.value.riskType = "疾病";
    formData.value.age =
      formData.value.identityNum && formData.value.identityNum !== ""
        ? IdCardGetInfo(formData.value.identityNum, 3)
        : "";
    formData.value.birthDate =
      formData.value.identityNum && formData.value.identityNum !== ""
        ? IdCardGetInfo(formData.value.identityNum, 1)
        : "";
  }

  caseInfo.inputList.map((it: InputListItem) => {
    it.type =
      formData.value[it.name] && formData.value[it.name] !== ""
        ? "readOnly"
        : it.type;
    if (
      formData.value.age &&
      formData.value.age < 18 &&
      it.name === "identityNum"
    ) {
      it.ageJudge = 1;
    }
    if (
      formData.value.age &&
      formData.value.age < 18 &&
      (it.name === "guardianName" ||
        it.name === "guardianIdentityNum" ||
        it.name === "guardianTel")
    ) {
      it.vIF = true;
    }
  });
}

const subClick = ref(false);
const Verification = () => {
  let veryFalg = true;

  try {
    caseInfo.inputList.forEach((it: InputListItem) => {
      if (!it.vIF) return;
      if (
        it.require &&
        ((!formData.value[it.name] && formData.value[it.name] !== 0) ||
          formData.value[it.name] === "" ||
          (it.name === "idCradImageList" &&
            formData.value[it.name].length === 0))
      ) {
        throw "";
      }
      if (
        formData.value[it.name] &&
        it.title === "证件号码" &&
        !idValidation(formData.value[it.name])
      ) {
        throw "证件号码格式不正确";
      }
      if (
        formData.value[it.name] &&
        it.title === "监护人身份证号" &&
        !idValidation(formData.value[it.name])
      ) {
        throw "监护人身份证格式不正确";
      }
      if (
        formData.value[it.name] &&
        it.very === "name" &&
        !nameValidation(formData.value[it.name]) &&
        it.vIF
      ) {
        let errorMsg = "";
        switch (it.name) {
          case "guardianName":
            errorMsg = "请输入正确的监护人姓名";
            break;
          default:
            errorMsg = "请输入正确的" + it.title;
        }
        throw errorMsg;
      }
      if (
        formData.value[it.name] &&
        it.very === "phone" &&
        !phoneValidation(formData.value[it.name]) &&
        it.vIF
      ) {
        let errorMsg = "";
        switch (it.name) {
          case "guardianTel":
            errorMsg = "监护人手机号格式不正确";
            break;
          case "contactTel":
            errorMsg = "手机号格式不正确";
        }
        throw errorMsg;
      }
      const reg2 = /[^\u4E00-\u9FA5A-Za-z0-9]/g;
      if (it.title === "科室" && reg2.test(formData.value.deptName)) {
        throw "科室名称应该是10字以内的中文、英文、数字";
      }
      const reg3 = /[^\u4E00-\u9FA5A-Za-z0-9]/g;
      if (it.title === "病症") {
        const inpatientNumberFlag = reg3.test(formData.value.diseaseName);
        if (inpatientNumberFlag) {
          throw "病症应是15位以内的中文、英文、数字";
        }
      }
    });
  } catch (errorMsg: any) {
    veryFalg = false;
    if (errorMsg && errorMsg !== "") {
      alertFn(errorMsg);
    }
  }
  return veryFalg;
};
const getIndex = () => {
  let newIndex;
  const infoArray = caseInfo.inputList;
  for (const key in infoArray) {
    if (
      infoArray[key].vIF &&
      infoArray[key].require &&
      (!formData.value[infoArray[key].name] ||
        formData.value[infoArray[key].name] === "" ||
        formData.value[infoArray[key].name].length === 0)
    ) {
      newIndex = key;
      break;
    }
  }
  return newIndex;
};
const scrollFn = (div: string) => {
  const node = document.getElementById(div);
  const top = <number>node?.offsetTop;
  window.scrollTo(0, top - 50);
};
const save = () => {
  let div = "infoItem" + getIndex();
  scrollFn(div);
  if (router.currentRoute.value.query.type === "detail") {
    router.push("/recordList");
    return;
  }

  subClick.value = true;
  if (!Verification()) return;
  alertData.alertContent =
    "<p style='font-size:3.73vw;line-height:6.67vw;color:#555;'>请确认提交所填写信息无误，信息提交后不支持修改！</p>";
  alertData.alertType = "comfirm";
  alertData.bntText = "确认";
  alertData.cancelBnt = "返回";
  alertFlag.value = true;
};
const subFlag = ref(true);
const baseInfoSave = () => {
  const { selectType, address, ...data } = formData.value;
  data["city"] = address;
  //渠道号
  data["requirementSource"] = sessionStorage.getItem("requirementSource") || "";
  if (!subFlag.value) return;
  subFlag.value = false;
  store
    .dispatch("baseInfoSave", data)
    .then((res) => {
      sessionStorage.setItem("sheetId", res.data.sheetId);
      router.push("/uploading");
      subFlag.value = true;
    })
    .catch(() => {
      subFlag.value = true;
    });
};
const selectConfirm = (value: any, index: any) => {
  showPicker.value = false;
  let selectV = "",
    selectId;
  if (value.length && value.length > 1) {
    value.map((it: any) => {
      selectV = selectV === "" ? it.text : selectV + "-" + it.text;
    });
  } else {
    selectV = value.text;
    selectId = value.id;
  }
  const type = <any>formData.value.selectType,
    typeId = type ? type.split("Name")[0] : undefined;
  formData.value[type] = selectV;
  if (type != typeId) {
    formData.value[typeId] = selectId;
  }
  formData.value.selectFlag = false;
};
const formatDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const dateConfirm = (value: Date) => {
  formData.value[formData.value.selectType] = formatDate(value);
  show.value = false;
};
const inputBlur = (item: InputListItem) => {
  caseInfo.inputList.forEach((it: InputListItem) => {
    if (item.name === "identityNum") {
      const identityNum = formData.value.identityNum;
      formData.value.age =
        identityNum && identityNum !== "" && idValidation(identityNum)
          ? IdCardGetInfo(identityNum, 3)
          : "";
      formData.value.birthDate =
        identityNum && identityNum !== "" && idValidation(identityNum)
          ? IdCardGetInfo(identityNum, 1)
          : "";
      if (
        formData.value.age &&
        formData.value.age < 18 &&
        it.name === "identityNum"
      ) {
        it.ageJudge = 1;
      }
      if (
        it.name === "guardianName" ||
        it.name === "guardianIdentityNum" ||
        it.name === "guardianTel"
      ) {
        it.vIF = formData.value.age && formData.value.age < 18 ? true : false;
      }
    }
    if (item.name === it.name) {
      it.errorJudge = true;
    }
  });
};
const radioChange = (item: InputListItem, type: string) => {
  // if(item.title==='出险类型'||item.title==='是否为交通事故'){
  // 	caseInfo.inputList.map((it:InputListItem)=>{
  // 		if((it.title==="事故时间"||it.title==='事故地点'||it.title==="患者类型"||it.title==='是否为交通事故')&&item.title==='出险类型'){
  // 			it.vIF=formData.value.dangerType&&formData.value.dangerType===2?true:false
  // 		}
  // 		if((it.title==='事故类型'||it.title==='责任方')&&item.title==='是否为交通事故'){
  // 			it.vIF=formData.value.isTrafficAccident===1?true:false
  // 		}
  // 	})
  // }
};
const selectCancel = () => {
  caseInfo.inputList.forEach((it: InputListItem) => {
    if (formData.value.selectType === it.name) {
      it.errorJudge = true;
    }
  });
  showPicker.value = false;
};
const searchFlag = ref(true);
const hospitalSearch = (v: string) => {
  if (!searchFlag.value) return;
  searchFlag.value = false;
  store
    .dispatch("getHosipital", {
      params: {
        // "province":formData.value.address?.split("-")[0],
        // "city":formData.value.address?.split("-")[1],
        hospitalName: v,
      },
    })
    .then((res) => {
      caseInfo.inputList.map((it) => {
        if (it.title === "医院") {
          it.searchData = res.data;
          console.log(it);
        }
      });
      searchFlag.value = true;
    });
};
</script>
<style>
#baseInfo .infoItem {
  margin-top: 4.8vw;
}
</style>
<style scoped>
.van-picker {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.save {
  display: flex;
  width: 94.67vw;
  height: 11.93vw;
  background: linear-gradient(180deg, #88ffc3, #009f4f);
  border: 0.27vw solid #ffffff;
  box-shadow: 0 1vw 0vw 0vw rgba(0, 0, 0, 0.05);
  border-radius: 2.67vw;
  margin: 11.33vw auto 0 auto;
  font-size: 4.27vw;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.detial {
  position: relative;
}
.state {
  display: block;
  position: absolute;
  height: 5.2vw;
  line-height: 5.2vw;
  padding: 0 2.27vw;
  border-radius: 1.33vw;
  font-size: 2.93vw;
  right: 10vw;
  top: 5vw;
}
.state.state1 {
  color: #ff0000;
  border: #ff0000 solid 1px;
  background: #ffe5e5;
}
.state.state2 {
  background: #f39800;
  color: #fff;
}
</style>
