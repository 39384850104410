import request from '../../../api/request';
import { Module} from 'vuex'
import {RootState} from '../../interface'
import {ApplyState} from './interface'
import axios from 'axios';

const baseInfoModule:Module<ApplyState,RootState>={
  state: {
    idCardArray:[{'text':'身份证',"id":1},{"text":"居民户口簿","id":2}],
    formularyMethodNameArray:[
      {'id':1,"text":"职工医保"},
      {'id':2,"text":"居民医保"},
      {'id':3,"text":"自费"},
      {'id':4,"text":"一老一小"},
      {'id':5,"text":"新农合"},
      {'id':6,"text":"其他"},
      {'id':7,"text":"公费医疗"}
    ],
    accidentTypeArray:[
      {'id':1,"text":"单车事故"},
      {'id':2,"text":"双车事故"},
    ],
    accidentResponsibleArray:[
      {'id':1,"text":"本方"},
      {'id':2,"text":"对方"},
    ]
  },
  actions: {
    getInfo : ({commit},sheetId) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/healthy-future/get-record-detail/${sheetId}`).then(res =>{
              resolve(res)
          })
      })
    },
    getCity:()=>{
      return new Promise((resolve, reject)=>{
       axios.get('./cityChoose.json').then(res =>{
            resolve(res)
        })
      })
    },
    baseInfoSave:({commit},data)=>{
      return new Promise((resolve, reject)=>{
        request.post('/api/v1/healthy-future/save-basic-info',data).then(res =>{
            resolve(res)
        }).catch(res=>{
            reject(res)
        })
      })
    },
    applyStop:()=>{
      return new Promise((resolve, reject)=>{
        request.get('/api/v1/app/advance-apply/stop').then(res =>{
            resolve(res)
        })
      })
    }
  }

}

export default baseInfoModule

  