
import { createRouter,  createWebHashHistory,createWebHistory,RouteRecordRaw} from 'vue-router'
  import Home from '../pages/Home.vue'
  import GjwaYjwl from '../pages/GjwaYjwl.vue'
  import HomeAiwuyou from '../pages/HomeAiwuyou.vue'
  import hospitalList from '../pages/hospitalList.vue'
  import apply from '../pages/apply.vue'
  import baseInfo from '../pages/baseInfo.vue'
  import uploading from '../pages/uploading.vue'
  import recordList  from '../pages/recordList.vue'
  import recordDetail from "../pages/recordDetail.vue"
  import userAgreement from "../pages/userAgreement.vue"
  import privacyAgreement from '../pages/privacyAgreement.vue'
  import childrenPrivacy from "../pages/childrenPrivacy.vue"
  import userAgreementZK from "../pages/userAgreementZK.vue"
  import authFail from "../pages/authFail.vue"
  import citysHS from "../pages/citysHS.vue"
  import citysHFS from "../pages/citysHFS.vue"
  import ewm from "../pages/ewm.vue"
  import familyChoose from "../pages/familyChoose.vue"
  import privacyAWY from "../pages/privacyAWY.vue"
  import homeKangfubao from "../pages/homeKangfubao.vue"
  import WeiyiAiwuyou from '../pages/WeiyiAiwuyou.vue'
  const routes:RouteRecordRaw[] = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { title: '愈健未来' }
    },
    {
      path: '/homeAiwuyou',
      name: 'HomeAiwuyou',
      component: HomeAiwuyou,
      meta: { title: '私家肿瘤管理' }
    },
    {
      path: '/homeXinnaokang',
      name: 'homeXinnaokang',
      component: () => import('../pages/HomeXinnaokang.vue'),
      meta: { title: '1200长寿心脑康' } 
    },
    {
      path: '/homeZhuoKang',
      name: 'homeZhuoKang',
      component: () => import('../pages/homeZhuoKang.vue'),
      meta: { title: '卓康中汇防癌' } 
    },
    {
      path: '/homeYoubao',
      name: 'homeYoubao',
      component: () => import('../pages/HomeYoubao.vue'),
      meta: { title: '院后居家康复' }
    },
    {
      path: '/homeYinghai',
      name: 'homeYinghai',
      component: () => import('../pages/HomeYinghai.vue'),
      meta: { title: '愈健未来-康复权益包' }
    },
    {
        path: '/weiyiAiwuyou',
        name: 'WeiyiAiwuyou',
        component: WeiyiAiwuyou,
        meta: { title: '私家肿瘤管理' }
      },
    {
      path: '/gjwayjwl',
      name: 'GjwaYjwl',
      component: GjwaYjwl,
      meta: { title: '愈健未来康复权益包' }
    },
    {
      path: '/hospitalList',
      name: 'hospitalList',
      component: hospitalList,
      meta:{title:'服务区域'}
    },
    {
      path: '/apply',
      name: 'apply',
      component: apply,
      redirect:"/baseInfo",
      meta:{title:'信息提交'},
      children:[
        {
          path: '/baseInfo',
          name: 'baseInfo',
          component: baseInfo
        },
        {
          path: '/uploading',
          name: 'uploading',
          component: uploading
        }
      ]
    },
    {
      path: '/recordList',
      name: 'recordList',
      component: recordList,
      meta:{title:'服务记录'}
    },
    {
      path: '/recordDetail',
      name: 'recordDetail',
      component: recordDetail,
      redirect:"/recordDetail/baseInfo",
      meta:{title:'服务详情'},
      children:[
        {
          path: '/recordDetail/baseInfo',
          name: '/recordDetail/baseInfo',
          component: baseInfo
        },
        {
          path: '/recordDetail/uploading',
          name: '/recordDetail/uploading',
          component: uploading
        }
      ]
    },
    {
      path: '/userAgreement',
      name: 'userAgreement',
      component: userAgreement,
      meta:{title:'愈健未来-整合型患者管理计划用户服务协议'}
    },
    {
      path: '/privacyAgreement',
      name: 'privacyAgreement',
      component: privacyAgreement,
      meta:{title:'远盟卓康服务平台用户隐私政策'}
    },
    {
      path: '/userAgreementZK',
      name: 'userAgreementZK',
      component: userAgreementZK,
      meta:{title:'远盟卓康服务平台用户协议'}
    },
    {
      path: '/childrenPrivacy',
      name: 'childrenPrivacy',
      component: childrenPrivacy,
      meta:{title:'远盟卓康服务平台儿童隐私保护政策'}
    },
    {
      path: '/authFail',
      name: 'authFail',
      component: authFail,
      meta:{title:'鉴权失败'}
    },
    {
      path: '/ewm',
      name: 'ewm',
      component: ewm,
      meta:{title:'服务详情'}
    },
    {
      path: '/citysHS',
      name: 'citysHS',
      component: citysHS,
      meta:{title:'护士上门城市清单'}
    },
    {
      path: '/citysHFS',
      name: 'citysHFS',
      component: citysHFS,
      meta:{title:'康复师上门城市清单'}
    },
    {
      path: '/familyChoose',
      name: 'familyChoose',
      component: familyChoose,
      meta:{title:'家庭成员'}
    },
    {
      path:'/privacyAWY',
      name:'privacyAWY',
      component:privacyAWY,
      meta:{title:'爱无忧-特药卡个人信息收集处理单独告知书'}
    },
    {
      path:'/homeKangfubao',
      name:'homeKangfubao',
      component:homeKangfubao,
      meta:{title:'愈健未来-康复权益包'}
    }
    
  ]
  
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })
  
  export default router
  router.beforeEach((to, from, next) => {
    if(to.meta.title&&to.meta.title!==''){
      document.title = `${to.meta.title}`;
    }
    next()
    
});