<template>
		<div class="detail">
			<ul class="tab">
				<li v-for="item in tabList" @click="changeTab(item.code)" :class="{current:item.code===currentTab}">
					{{item.name}}
				</li>
			</ul>
			<router-view></router-view>
		</div>
</template>
<script setup lang="ts">
import { ref ,onMounted} from 'vue';
import {useStore} from 'vuex';
import router from '../router';
const store=useStore();
const tabList=[
	{"name":"基本信息","code":1},
	{"name":"材料信息","code":2}
]
const currentTab=ref(1);
currentTab.value=router.currentRoute.value.name==="/recordDetail/uploading"?2:1;
const changeTab=(code:number)=>{
	currentTab.value=code;
	const upType=router.currentRoute.value.query.upType;
	const serviceStatus=router.currentRoute.value.query.serviceStatus
	if(code===1){
		router.replace({"path":"/recordDetail","query":{"type":"detail",'upType':upType,"serviceStatus":serviceStatus}});
	}else if(code===2){
		let type:string=upType==='up2'?upType:'detail';
		router.replace({"path":"/recordDetail/uploading","query":{"type":type,'upType':upType,"serviceStatus":serviceStatus}});
	}
}


</script>
<style scoped>

.tab{
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 15vw;
	border-bottom: #009F4F solid 0.26vw;
	box-shadow: rgba(0, 0, 0, 0.05) 0 2vw 1vw;
}
.tab li{
	width: 43.87vw;
	height: 7.6vw;
	text-align: center;
	line-height: 7.6vw;
	background: #E5F5ED;
	border:#009F4F solid 0.13vw;
	border-radius: 2vw;
	font-size: 3.73vw;
	color: #000;
	font-weight: bold;
}
.tab li.current{
	background: #009F4F;
	color: #fff;
}
.detail{
	padding-bottom: 5vw;
}
</style>