<template>
  <div class="upBox">
    <ul class="imgListBox" >
      <li v-for="(item,index) in imgList">
        <img @click="imgClick(item.uploadUrl)" :src="item.uploadUrl" alt="">
        <img v-if="item.reviewStatus!==1&&pageType!=='detail'" class="close" @click="delImg(item.id,index)" src="../assets/images/close.png" alt=""/>
      </li>
      <li class="addBox" @click="upImg" v-if="pageType!=='detail'&&(imgList&&imgList.length<limit||!imgList)">
        <img src="../assets/images/addImg.png" alt=""/>
      </li>
    </ul>
    <alert v-if="alertFlag" :alertData="alertData" @sure="alertSure" @cancel="cancel"></alert>
  </div>
</template>
<script type="text/babel" lang="ts" setup>
import {ref,reactive} from "vue";
import { useStore } from "vuex";
import { AlertData } from "./interface/Alert-d";
import { ImgUpItem,ImgShowItem } from "./interface/imgUp-d";
import wx from "weixin-js-sdk-ts";
import waring from "../assets/images/waring.png"
const store=useStore();
const emit = defineEmits<{
  (e: 'del',id:any): void
  (e:'imgClick',imgLink:string):void
  (e: 'uplodad', v:any): void
  (e:'chooseSucess'):void
  (e:'chooseFail'):void
}>()
const imgClick=(url:string)=>{
  emit("imgClick",url)
}
const alertData=reactive<AlertData>({
        alertTitle:"温馨提示",
	      alertContent: "111",
        bntText:"我知道了",
        pwidth:525,
        alertType:"alert",
        icon:waring,
        cancelBnt:"取消"
})
const alertFlag=ref(false)
const props=defineProps<{
      materialType:number,
      imgList:any,
      limit:number,
      pageType:string
}>();
const wexinConfig= ()=>{
  return new Promise((resolve, reject) => {
    const url=window.location.href.split('#')[0]
    store.dispatch("wxConfig",url).then( res=>{
      const config = res.data;
      wx.config({
        debug: false, 
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp:config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature,// 必填，签名，见附录1
        jsApiList: [
          'chooseImage','uploadImage'
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList:[]
      });
    })
			wx.ready(() => resolve(wx))
			wx.error(reject)
	})

}
const upImg= ()=>{
wexinConfig().then(()=>{
    const imgLength=props.imgList?props.imgList.length:0
    wx.chooseImage({
					count: props.limit-imgLength, // 默认9
					sizeType: ['compressed'],//['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						const localIds= res.localIds; 
            wxUpImg(localIds);
            emit("chooseSucess")
            
					},
					fail: (res)=>{
            alertData.alertType="alert";
            alertData.alertTitle="温馨提示"
            alertData.alertContent="选择失败,请重新再试";
            alertData.bntText="我知道了";
            alertFlag.value=true;
            emit("chooseFail")
					},
          cancel(){
            emit("chooseFail")
            console.log('用户取消选择')
          }
				});
  })
  
}
let serverIds=ref<string[]>([]);
const wxUpImg=(localIds:string[])=>{
   if(localIds.length>0){
    const localId=<string>localIds.pop()
      wx.uploadImage({
      localId:localId, // 需要上传的图片的本地ID，由chooseImage接口获得
      isShowProgressTips: 0, // 默认为1，显示进度提示
      success: function (resp) {
        const serverId = resp.serverId; // 返回图片的服务器端ID
        serverIds.value.push(serverId)
        console.log(serverIds.value,localIds,serverId)
        wxUpImg(localIds);
      }
    });
  }else{
    const upData={
      "mediaIds":serverIds.value,
      "materialType":props.materialType,
      "sheetId":sessionStorage.getItem("sheetId")
    }
    emit("uplodad",{"materialType":props.materialType,'upData':upData})
    serverIds.value=[];
  }
  
}
const delId=ref();
const delImg=(id:string,index:number)=>{
    alertData.alertType="comfirm";
    alertData.alertTitle=""
    alertData.alertContent="是否确认删除图片";
    alertData.bntText="确定";
    alertFlag.value=true;
    delId.value=id;
}
const alertSure=()=>{
  alertFlag.value=false;
  if(alertData.alertContent==="是否确认删除图片"){
     store.dispatch("delImg",delId.value).then(res=>{
        emit("del",delId.value)
      })
  }
}
const cancel=()=>{
  alertFlag.value=false
}
</script>
<style type="text/css" scoped>

.imgListBox{
  display: flex;
  flex-wrap: wrap;
}
.imgListBox li{
  position: relative;
  margin:0 5vw 4vw 0;
}
img{
  display: block;
  width:15.87vw;
  height: 15.87vw;
  border-radius: 1vw;
  
}
.close{
  position:absolute;
  height: 4.27vw;
  width: 4.27vw;
  top:0;
  right: 0;
}
.addBox{
  position: relative;
  width:15.87vw;
  height: 15.87vw;
}
.addBox input[type='file']{
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>