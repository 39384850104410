import request from '../../../api/request';
import {Module} from 'vuex'
import {RootState} from '../../interface'

const messageModule:Module<any,RootState>={
  state: {
  },
  actions: {
    sendMessage: ({commit},data) => {
      return new Promise((resolve, reject)=>{
          request.post('/api/v1/app/advance-consultation',data).then(res =>{
              resolve(res)
          }).catch(res=>{
             reject(res)
          })
      })
    },
    messageList:({commit},recordId) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/app/advance-consultation//info/${recordId}`).then(res =>{
              resolve(res)
          })
      })
    }
  }
}

export default messageModule

  