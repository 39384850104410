<template>
    <div class="top">
        <img class="logo" alt="" src="../assets/images/logo.png" />
        <p class="discript">“愈健未来-整合型患者管理计划”是在出院后风险窗口期内，为健康保险理赔客户提供的由家庭医生、专科医师、家庭护师、康复医师、照护顾问等组成的整合型医疗健康小组，遵循标准化医学延续照护路径，协同为客户提供以提高自我管理效能为目的健康照护服务，包括患者管理、家庭医疗、家庭护理、家庭康复和居家照护指导等服务内容，以降低复发率和衍生风险致病率，实现对风险的主动管理。</p>
    </div>
    <img alt="" src="../assets/images/indexDeatil1.png" />
    <img alt="" src="../assets/images/indexDeatil2.png" />
    <div class="detailBox">
        <img alt="" src="../assets/images/indexDeatil3.png" />
        <img class="getHospitalList" @click="linkfor('/hospitalList')" alt="" src="../assets/images/bntHospital.png" />
    </div>
     <div class="agreeBox">
        <span @click="check" :class="{check:true,checked:checkFlag}">我已阅读并了解以下服务协议及政策内容</span>
        <a href="javascript:void(0)" @click="$router.push('/userAgreement')">《愈健未来-整合型患者管理计划用户服务协议》</a>
        <a href="javascript:void(0)" @click="$router.push('/childrenPrivacy')">《远盟卓康服务平台儿童隐私保护政策》</a>
        <a href="javascript:void(0)" @click="$router.push('/userAgreementZK')">《远盟卓康服务平台用户协议》</a>
        <a href="javascript:void(0)" @click="$router.push('/privacyAgreement')">《远盟卓康服务平台用户隐私政策》</a>
    </div>
    <div class="bntBox">
        <span @click="goRecord" class="record">我的订单</span>
        <span @click="getService" :class="{getService:true,disabled:!checkFlag}">使用服务</span>
    </div>
    <Alert v-if="alertFlag" :alertData="alertData" @sure="alertSure"></Alert>
</template>
<script setup lang="ts">
import {ref,reactive,onMounted} from 'vue'
import {useStore} from 'vuex';
import { useRouter } from 'vue-router'
import { AlertData } from '../components/interface/Alert-d';
import waringImg from '../assets/images/waring.png'
const router = useRouter();
const store=useStore();

let queryData={
        policyNo:router.currentRoute.value.query.policyNo,
        userName:router.currentRoute.value.query.userName
}
store.commit("upQuery",queryData);
sessionStorage.setItem("upQuery",JSON.stringify(queryData))
const alertData=reactive<AlertData>({
        alertTitle:"温馨提示",
	    alertContent: "111",
        bntText:"我知道了",
        pwidth:525,
        alertType:"alert",
        icon:waringImg
})
const alertFlag=ref<boolean>(false)
const checkFlag=ref(false);
const check=()=>{
    checkFlag.value=!checkFlag.value
}

const linkfor=(url:string)=>{
    router.push(url)
    
}
const subFlag=ref(true);
const getService=()=>{
    if(!checkFlag.value)return;
    if(!subFlag.value)return;
    subFlag.value=false;
    store.dispatch('useService').then(res =>{
        sessionStorage.setItem("userInfo",JSON.stringify(res.data));
        router.push({"path":'/apply',"query":{"type":"frHome"}});
        subFlag.value=true;
    }).catch(()=>{
        subFlag.value=true
    })
}
const goRecord=()=>{
    router.push('/recordList')
}

const alertSure=()=>{
    alertFlag.value=false
}
</script>
<style scoped>
.top{
    background: url(../assets/images/indexTopBg.png) no-repeat;
    background-size: cover;
    height: 62.93vw;
    overflow: hidden;
}
.top .logo{
    width: 30.27vw;
    margin:6.8vw auto 8.27vw auto;
}
.top .discript{
    width: 90.27vw;
    margin: auto;
    font-size: 3.2vw;
    color: #fff;
    line-height: 4.8vw;
}
img{
    display: block;
    width: 95.47vw;
    margin:4.4vw auto 0 auto;
}
.detailBox{
    position: relative;
    margin-top: 8vw;
}
.getHospitalList{
    position:absolute;
    width: 77.6vw;
    height: 11.6vw;
    top: 48.2vw;
    left: 50%;
    margin-left: -38.8vw;
}
.agreeBox{
    width: 76vw;
    line-height: 6vw;
    margin: 5.8vw auto 5.33vw auto;
}
.agreeBox span,.agreeBox a{
    display:block;
    font-size: 3.47vw;
}
.agreeBox span{
    color: #666666;
}
.agreeBox a{
    color: #01B6FF;
    /* text-decoration: underline; */
}
.check{
    position: relative;
}
.check::after{
    position: absolute;
    height: 5.2vw;
    width: 5.2vw;
    background: url(../assets/images/check.png) no-repeat;
    background-size: 5.2vw 5.2vw;
    display: block;
    content: "";
    left: -7vw;
    top:0.5vw;
}
.check.checked::after{
    background: url(../assets/images/checked.png) no-repeat;
    background-size: 5.2vw 5.2vw;
}
.bntBox {
    width: 96vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 9.37vw;
}
.bntBox span{
    display: block;
    width: 36vw;
    height: 11.93vw;
    text-align: center;
    line-height: 11.93vw;
    font-size: 4.27vw;
    font-weight: bold;
    border-radius: 2.67vw;
}
.bntBox span.getService{
    background: linear-gradient(180deg, #88FFC3, #009F4F);
    color: #fff;
    width: 53.33vw;
}
.bntBox span.getService.disabled{
    opacity: 0.5;
}
.bntBox span.record{
    box-shadow: 0 1vw 0 0 rgba(0, 0, 0, 0.05);
    border: #009F4F solid 0.27vw;
    color: #009F4F;
}
</style>