<template>
  <div class="bg">
      <div :class="{alertBox:true,comfirm:props.alertData.alertType==='comfirm'}" :style="{height:props.alertData.pHeight?(props.alertData.pHeight)/7.5+'vw':'auto',width:props.alertData.pwidth/7.5+'vw'}">
        <img class="icon" :src="props.alertData.icon" :style="props.alertData.iconStyle" alt=""/>
        <span v-if="props.alertData.alertTitle!=''" class="title">{{props.alertData.alertTitle}}</span>
        <p v-if="alertData.alertType==='alert'||alertData.alertType==='comfirm'||alertData.alertType==='comfirmNew'" v-html="props.alertData.alertContent" :style="{width:(props.alertData.pwidth-80)/7.5+'vw'}" class="alertContent"></p>
        <div v-if="alertData.alertType==='comfirmCase'" class="cancelBOX">   
            <p><input type="radio" v-model="cancelCase" name="paycase" id="case1" value="1" ><label for="case1">无需进行垫付</label></p>
            <p><input type="radio" v-model="cancelCase" name="paycase" id="case2" value="2" ><label for="case2">即将出院或已经出院</label></p>
            <p><input type="radio" v-model="cancelCase" name="paycase" id="case3" value="3" ><label for="case3">无法提供相关资料</label></p>
        </div>
        <div v-if="alertData.alertType!=='comfirmNew'" class="bntBox">
          <span v-if="props.alertData.alertType==='comfirm'" @click="cancel" class="bnt cancelC">{{props.alertData.cancelBnt}}</span>
          <span @click="sure" :class="{bnt:true,subBg:true,dis:props.alertData.alertType==='comfirmCase'&&!cancelCase}">{{props.alertData.bntText}}</span>
          <span v-if="props.alertData.alertType==='comfirmCase'" @click="cancel" class="cancel">{{props.alertData.cancelBnt}}</span>
        </div>
        <div v-if="alertData.alertType==='comfirmNew'" class="comfirmNewBntBox bntBox">
          <span @click="sure" class="bnt subBg" v-html="props.alertData.bntText"></span>
          <span @click="cancel" class="bnt cancelC" v-html="props.alertData.cancelBnt"></span>
        </div>
      </div>
  </div>
</template>
<script type="text/babel" lang="ts" setup>
import { ref } from 'vue'
import {AlertData} from './interface/Alert-d'
const props = defineProps<{
      alertData:AlertData
}>()
const emit = defineEmits<{
	(e: 'sure',v:string): void
  (e: 'cancel'): void
}>()
const sure=()=>{
  emit("sure",cancelCase.value)
}
const cancel=()=>{
  emit("cancel")
}
const cancelCase=ref()
</script>
<style type="text/css" >
  .bg{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000
  }
  .alertBox{
    background: #fff;
    box-shadow: 0px 0.7vw 0.7vw 0px rgba(0, 0, 0, 0.2);
    border-radius: 2vw;
    padding-bottom: 11vw;
  }
  .alertBox .icon{
    display:block;
    width: 13.07vw;
    height: 13.07vw;
    margin: 10.93vw auto 7.5vw auto;
  }
  .alertBox .title{
    font-size: 4.27vw;
    color:#FF7173;
    display: block;
    text-align: center;
  }
  .alertBox .warning.title{
    color: #FF7777;
  }
  .alertBox .alertContent{
    margin: auto;
    color: #666666;
    font-size: 3.73vw;
    text-align: center;
    margin: 5vw auto 6vw auto;
    padding: 0 2vw;
  }
  .alertBox .bnt{
    display: block;
    width: 56vw;
    height: 10.6vw;
    background: linear-gradient(180deg, #88FFC3, #009F4F);
    border: 0.27vw solid #FFFFFF;
    box-shadow: 0 1vw 0 0 rgba(0, 0, 0, 0.05);
    border-radius: 3vw;
    margin: auto;
    font-size: 4.27vw;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 11vw;
  }
  .comfirmNewBntBox .bnt{
    font-weight: normal;
    line-height: 5vw;
    height: auto;
    font-size: 3.2vw;
    padding: 1vw 0;
    width:58.67vw;
  }
  .comfirmNewBntBox .bnt.cancelC{
    margin-top: 6vw;
  }
  .alertBox .cancel{
    display: block;
    font-size: 3.43vw;
    color: #000;
    margin-top:4.93vw;
    text-decoration: underline;
    text-align: center;
  }
  .alertBox .cancelBOX{
     margin-bottom: 6vw;
  }
  .alertBox .cancelBOX p{
    display:flex;
    width:42vw;
    margin:8.2vw auto 0 auto;
    align-items:center;
    text-align:left
  }
  .alertBox .cancelBOX label{
    font-size: 3.73vw;
    color: #000;
    padding-left: 4.7vw;
   
  }
  .alertBox.comfirm .bntBox{
    display: flex;
    width: 95%;
    margin: auto;
  }
  .alertBox.comfirm .bntBox span{
     width: 29.33vw;
   }
  .alertBox .cancelC{
     background: linear-gradient(180deg, #FF7173, #FF7173, #FF888A, #FF7173);
   }
</style>