import Alert from "./alert";
import { StaticDataItem } from "../store/interface";

export const getState=(data:StaticDataItem[],key:string|number,type:string)=>{
    let v;
    data.forEach((it:StaticDataItem)=>{
        if(key===it.text||key===it.id){
            switch(type){
                case 'getId':v=it.id;
                            break;
                case 'getText':v=it.text;
                            break;
            }
        }
    })
    return v
}
export const idValidation = (idCard:string) =>{
    var regIdCard = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$/,
		flag = true

	if (!regIdCard.test(idCard)) {
		console.log('身份证号格式输入错误1', idCard)
		flag = false
	} else {
		if (idCard.length == 18) {
			var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)
			var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2)
			var idCardWiSum = 0
			for (var i = 0; i < 17; i++) {
				idCardWiSum += <any>idCard.substring(i, i + 1) * idCardWi[i]
			}

			var idCardMod = idCardWiSum % 11
			var idCardLast = idCard.substring(17)
			if (idCardMod == 2) {
				if (!(idCardLast == 'X' || idCardLast == 'x')) {
					console.log('身份证号输入错误2')
					flag = false
				}
			} else {
				if (idCardLast != <any>idCardY[idCardMod]) {
					console.log('身份证号输入错误3')
					flag = false
				}
			}
		}
	}
	return flag
}
export const IdCardGetInfo=(UUserCard:string,num:number)=>{
	if (num == 1) {
		//获取出生日期
		let birth
		birth =
			UUserCard.substring(6, 10) +
			'-' +
			UUserCard.substring(10, 12) +
			'-' +
			UUserCard.substring(12, 14)
		return birth
	}
	if (num == 2) {
		//获取性别
		if (parseInt(UUserCard.substring(16, 1)) % 2 == 1) {
			return '男'
		} else {
			return '女'
		}
	}
	if (num == 3) {
		//获取年龄 
		var myDate = new Date()
		var month = myDate.getMonth() + 1
		var day = myDate.getDate()
		const cardYear=parseInt(UUserCard.substring(6, 10))
		var age = myDate.getFullYear() - cardYear - 1
		if (
			parseInt(UUserCard.substring(10, 12)) < month ||
			(parseInt(UUserCard.substring(10, 12)) == month && parseInt(UUserCard.substring(12, 14)) <= day)
		) {
			age++
		}
		return age
	}
}
export const nameValidation=(name:string)=>{
	var reg = /^([\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}|[a-zA-Z.s]{1,30})$/g,
		flag = true
	if (!reg.test(name)) {
		console.log('名字格式不正确')
		flag = false
	}
	return flag
}
export const phoneValidation =  (phoneNum:number)=> {
	var reg = /^1[0-9]{10}$/,
		flag = true
	if (!reg.test(phoneNum+"")) {
		console.log('手机号格式输入不正确！', phoneNum)
		flag = false
	}
	return flag
}
let mobileDetect = {
	IosAndroid: function(){
		var userAgent = window.navigator.userAgent;
		if(/(iPhone|iPad|iPod|iOS)/i.test(userAgent)){
			return 'ios'
		}else if(/(android|Android)/i.test(userAgent)){
			return 'android'
		}else{
			return 'pc'
		}
	},
	mobileModel: function(){
		if(this.IosAndroid() == 'ios'){
			return window.navigator.userAgent.split(";")[0].split('(')[1]
		}else if(this.IosAndroid() == 'android'){
			return window.navigator.userAgent.split(";")[2].split("Build")[0]
		}else{
			return 'PC'
		}
	},
	operateSystem: function(){
		if(this.IosAndroid() == 'ios'){
			return 'IOS'+window.navigator.userAgent.split("OS")[1].split("like")[0]
		}else if(this.IosAndroid() == 'android'){
			return window.navigator.userAgent.split(";")[1]
		}else{
			return window.navigator.userAgent.split("Chrome/")[1].split(' ')[0]
		}
	}
}
export const statisticsSaveJsonp=(newdata:any)=>{
	let _statisticsUrl = 'https://statistics.healthlink.cn/';
	if (window.location.host.indexOf('lvtest') != -1 || window.location.host.indexOf('172.') != -1 || window.location.host == '') {
		_statisticsUrl = 'https://statisticstest.healthlink.cn/'
	}
	log(newdata)
	function log(data:any) {
		let timing=window.performance && window.performance.timing 
		data.openId = localStorage.getItem('hbsAppOpenId')
		data.projectType = 'coralHealth'
		data.pageUrl = window.location.href
		data.equipmentInfo = window.navigator.userAgent
		data.mobileModel = mobileDetect.mobileModel()
	    data.operateSystem = mobileDetect.operateSystem()
		data.screenX = window.innerWidth
		data.screenY = window.innerHeight
		data.version = '1'
		if(timing){
			let timer = setInterval(()=> {
				if (0 !== timing.loadEventEnd) {
					clearInterval(timer);
					data.loadTime=timing.loadEventEnd-timing.navigationStart;
					if (data.openId) {
						var url = _statisticsUrl + 'operate/log/save?'

						for (let key in data) {
							url += `${key}=${encodeURIComponent(data[key])}&`
						}
						url = url.substring(0, url.length - 1)
						new Image().src = url
						console.log(url)
					}
				   
				}
			})
		}
		
	}
}
export const EventUtil = {
	addHandler: function (element:any, type:any, handler:any) {
		if (element.addEventListener) element.addEventListener(type, handler, false)
		else if (element.attachEvent) element.attachEvent('on' + type, handler)
		else element['on' + type] = handler
	},
	removeHandler: function (element:any, type:any, handler:any) {
		if (element.removeEventListener) element.removeEventListener(type, handler, false)
		else if (element.detachEvent) element.detachEvent('on' + type, handler)
		else element['on' + type] = handler
	},
	listenTouchDirection: function (
		target:any,
		isPreventDefault:any,
		upCallback:any,
		downCallback:any,
	) {
		this.addHandler(target, 'touchstart', handleTouchEvent)
		this.addHandler(target, 'touchend', handleTouchEvent)
		this.addHandler(target, 'touchmove', handleTouchEvent)
		var startX:any
		var startY:any
		function handleTouchEvent(event:any) {
			switch (event.type) {
				case 'touchstart':
					startX = event.touches[0].pageX
					startY = event.touches[0].pageY
					break
				case 'touchend':
					var spanX = event.changedTouches[0].pageX - startX
					var spanY = event.changedTouches[0].pageY - startY
					
					console.log('spanX:', spanX) //[span] X
					console.log('spanY:', spanY) //[span] X


					if (spanY > 0) {
						//向下
						if (downCallback) downCallback()
					} else if (spanY < -0) {
						//向上
						if (upCallback) upCallback()
					}
				 break;
       
      }
    }
  },
};
export default{
    getState,
    Alert,
    idValidation,
	statisticsSaveJsonp,
	EventUtil,
	IdCardGetInfo
}
