import request from '../../../api/request';
import {Module} from 'vuex'
import {RootState} from '../../interface'
import {UploadingState} from './interface'
const HomeModule:Module<UploadingState,RootState>={
  state: {
    applyNo:""
  },
  mutations:{
    upApplyNo:(state,applyNo)=>{
      state.applyNo=applyNo;
    }
  },
  actions: {
    imageList : (state,sheetId) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/healthy-future/get-record-detail/${sheetId}`).then(res =>{
              resolve(res)
          })
      })
    },
    isTrafficAccident : (state,data) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/app/advance-apply/is-traffic-accident`,{
            params:{
              applyNo:data
            }
          }).then(res =>{
              resolve(res)
          })
      })
    },
    materialSubmit:({commit},param)=>{
      return new Promise((resolve, reject)=>{
        request.patch(`/api/v1/healthy-future/save-materials?sheetId=${param.sheetId}&materialNum=${param.materialNum}`).then(res =>{
            resolve(res)
        })
    })
    }
  }

}

export default HomeModule

  