
import waringImg from '../assets/images/waring.png'
export const Alert=(msg:string)=>{
    let div = document.createElement('div');
        div.id='popBg';
        div.innerHTML=`<div class="bg">
                <div class="alertBox" style="height: auto; width: 70vw;">
                    <img class="icon" src="${waringImg}" alt="">
                    <span class="title">温馨提示</span>
                    <p class="alertContent">${msg}</p>
                     <div class="bntBox">
                        <span id="close" onclick="close()" class="bnt subBg">我知道了</span>
                     </div>
                </div>
            </div>`
        document.body.appendChild(div);
        let closeNode=document.getElementById("close");
        closeNode&&closeNode.addEventListener('mousemove', (e)=>{
            e.stopPropagation();
            setTimeout((res:any)=>{
                close();
            },50)
            
        });
}
const close =()=>{
    let cancelNode=document.getElementById("popBg");
    if(cancelNode&&cancelNode.parentNode){
        cancelNode.parentNode.removeChild(cancelNode);
    }
    
}


export default Alert