<template>
    <div v-cloak id="hospitalList">
		<ul class="tab">
			<li  v-for="item in tabData">{{item.name}}</li>
		</ul>
		<ul class="list">
			<li v-for="item in listData">
				<span class="td1">{{item.provice}}</span>
				<span class="td2">{{item.city}}</span>
			</li>
		</ul>
	</div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { ref } from 'vue';
import {useStore} from 'vuex';
import store from '../store';
const tabData=[
    		{"code":1,"name":"省份"},
    		{"code":2,"name":"城市"}
]
const stroe=useStore;
const listData=ref()
axios.get('./city.json').then(res=>{
	console.log(res)
    listData.value=res.data;
})
</script>
<style scoped>
.tab{
    height: 9.33vw;
    display: flex;
    background: #49aeff;
    align-items: center;
    font-size: 3.2vw;
    color: #fff;
    line-height: 9.33vw;
}
.tab li{
    width: 50%;
    text-align: center;
    border-right: #fff solid 1px;
    height: 100%;
    
}
.tab li:last-child{
    border-right: none;
}
.list{
	width: 95.3vw;
	margin: auto;
	border-left:#ececec solid 1px;
	border-right:#ececec solid 1px;
	box-shadow: #e5e5e5 0 0 2vw;
}
.list li{
	display: flex;
	border-bottom: #e5e5e5 dashed 1px;
	align-items: stretch ;
	
}
.list li:last-child{
	border-bottom: #e5e5e5 solid 1px;
}
.list span{
	display: flex;
	align-items: center;
	text-align: center;
	padding: 2vw;
	justify-content: center;
	font-size: 3.2vw;
	border-right: #e5e5e5 solid 1px;
	width: 50%;
}
.list span:last-child{
	border-right: none;
}
</style>