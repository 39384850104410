import { createStore , useStore as baseUseStore, Store} from 'vuex'
import { InjectionKey } from 'vue'
import { RootState,AllRootState } from './interface'
import request from '../api/request';
import HomeModule from './modules/home/home'
import uploading from './modules/apply/uploading'
import recordModule from './modules/record/record';
import baseInfoModule from './modules/apply/baseInfo'
import DelegationModule from './modules/Delegation/index'
import expresseModule from "./modules/express/index"
import messageModule from "./modules/leaveMessage/index"
import renewModule from "./modules/renew/index"

export default createStore<RootState>({
    state: {
        queryParams:"",
        channel:"",
        partner:""
    },
    mutations:{
        upQuery:(state:RootState,newData)=>{
            state.queryParams=newData.queryParams;
            state.channel=newData.channel;
            state.partner=newData.partner
        }
    },
    actions:{
        upImg : ({commit},imgData) => {
            return new Promise((resolve, reject)=>{
                request.post('/api/v1/healthy-future/upload',imgData).then(res =>{
                    resolve(res)
                }).catch(res=>{
                    reject(res)
                })
            })
        },
        wxUpImg: ({commit},imgData) => {
            return new Promise((resolve, reject)=>{
                request.post('/api/v1/healthy-future/upload-multiple',imgData).then(res =>{
                    resolve(res)
                }).catch(res=>{
                    reject(res)
                })
            })
        },
        idCardOCR : ({commit},data) => {
            return new Promise((resolve, reject)=>{
                request.post('/api/v1/app/ocr/idcard',data).then(res =>{
                    resolve(res)
                })
            })
        },
        getHosipital : ({commit},data) => {
            return new Promise((resolve, reject)=>{
                request.get('/api/v1/healthy-future/hospital',data).then(res =>{
                    resolve(res)
                })
            })
        },
        delImg:({commit},id) => {
            return new Promise((resolve, reject)=>{
                request.delete(`/api/v1/healthy-future/material/${id}`).then(res =>{
                    resolve(res)
                })
            })
        },
        wxConfig:({commit},data)=>{
            return new Promise((resolve, reject)=>{
                request.get('/api/v1/app/wechat/jsapi-signature',{
                    params:{
                        url:data
                    }
                }).then(res =>{
                    resolve(res)
                })
            })
        },
        isAdvanceCity:({commit},data)=>{
            return new Promise((resolve, reject)=>{
                request.get('/api/v1/app/advance-apply/is-advance-city',data).then(res =>{
                    resolve(res)
                })
            })
        },
        isFirstAdvance:({commit},data)=>{
            return new Promise((resolve, reject)=>{
                request.get('/api/v1/app/advance-check/is-first-advance',data).then(res =>{
                    resolve(res)
                })
            })
        },
        getQRCode:()=>{
            return new Promise((resolve, reject)=>{
                request.get('/api/v1/healthy-future/get-qr-code').then(res =>{
                    resolve(res)
                })
            })
        },
        familyCard:({},type)=>{
            return new Promise((resolve, reject)=>{
                request.get(`/api/v1/healthy-future/check-card-identity/${type}`).then(res =>{
                    resolve(res)
                }).catch(res=>{
                    reject(res)
                })
            })
        }
    },
    modules:{
        HomeModule,
        uploading,
        recordModule,
        baseInfoModule,
        DelegationModule,
        expresseModule,
        messageModule,
        renewModule
    }
})
export const key: InjectionKey<Store<RootState>> = Symbol('vue-store')

export function useStore<T=AllRootState> () {
    return baseUseStore<T>(key)
}
