import request from '../../../api/request';
import {Module} from 'vuex'
import {RootState} from '../../interface'

const DelegationModule:Module<any,RootState>={
  state: {
    
  },
  actions: {
    scheme : ({commit},recordId) => {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/app/advance-accredit/scheme/${recordId}`).then(res =>{
              resolve(res)
          })
      })
    },
    claimsAccount : ({commit},data) => {
      return new Promise((resolve, reject)=>{
          request.post('/api/v1/app/advance-accredit/claims-account',data).then(res =>{
              resolve(res)
          }).catch(res=>{
            reject(res)
         })
      })
    },
    hospitalAccount: ({commit},data) => {
      return new Promise((resolve, reject)=>{
          request.post('/api/v1/app/advance-accredit/hospital-account-submit',data).then(res =>{
              resolve(res)
          })
      })
    },
    hospitalAccountImage: ({commit},data) => {
      return new Promise((resolve, reject)=>{
          request.post('/api/v1/app/advance-accredit/hospital-account-image',data).then(res =>{
              resolve(res)
          })
      })
    },
    delegationRecord:({commit},recordId)=> {
      return new Promise((resolve, reject)=>{
          request.get(`/api/v1/app/advance-accredit/list/${recordId}`).then(res =>{
              resolve(res)
          })
      })
    },
    delHospitalAccountimage:({commit},data)=> {
      return new Promise((resolve, reject)=>{
          request.delete(`/api/v1/app/advance-accredit/hospital-account-image`,data).then(res =>{
              resolve(res)
          })
      })
    }
  }

}

export default DelegationModule

  