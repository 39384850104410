<template>
   <div class="infoItem">
      <span v-if="props.infoModel.title" class="modelTitle">{{props.infoModel.title}}</span>
      <ul class="infoBox">
        <li @click="LiClick(item)"  v-for="(item,index) in props.infoModel.inputList" :id="'infoItem'+index" :class="{styleNull:!item.vIF,'must':item.require,error:item.vIF&&item.require&&((!props.modelValue[item.name]&&props.modelValue[item.name]!==0)||props.modelValue[item.name]===''||props.modelValue[item.name].length===0)&&subClick}">
          <template v-if="item.vIF">
              <div class="inputBox">
                <img @click="question(item)" class="question" v-if="item.question&&$router.currentRoute.value.query.type!=='detail'" src="../assets/images/question.png" alt=""/>
                <span class="itemTitle" v-html="item.title"></span>
                <span v-if="item.type==='readOnly'" class="inputItem">{{props.modelValue[item.name]}}</span>
                <input class="inputItem" @blur="inputBlur(item)" :maxlength="item.maxLength" @input="$emit('update:modelValue', props.modelValue)" :placeholder="item.plalceHolder" v-if="(item.type==='text'||item.type==='number'||item.type==='password')" :type="item.type" v-model='props.modelValue[item.name]'>
                <div class="certificateBox" v-if="item.type==='certificateUp'||item.type==='certificateUpShow'">
                  <span v-if="!props.modelValue[item.name]||props.modelValue[item.name].length===0" @click="upCard(cItem)" v-for="cItem in item.cardList">{{cItem}}</span>
                  <div class="certificateShow" v-if="props.modelValue[item.name]&&props.modelValue[item.name].length>0">
                    <div class="imgBox">
                      <img v-for="item in props.modelValue[item.name]" :src="item.materialLink" alt=""/>
                    </div>
                    <span v-if="item.type==='certificateUp'" @click="upNew(item)" class="upNew">重新上传</span>
                  </div>
                </div>
                <div @click="select(item)" class="select" v-if="item.type==='select'||item.type==='date'">
                  <input  class="select inputItem" v-model='props.modelValue[item.name]'  :placeholder="item.plalceHolder" readonly unselectable="on" onfocus="this.blur()" type="text" />
               </div>
                <textarea :maxlength="item.maxLength" v-if="item.type==='textarea'" @blur="inputBlur(item)" :placeholder="item.plalceHolder" v-model.trim="props.modelValue[item.name]"></textarea>
                <div v-if="item.type==='radio'" class="checkBox">
                  <p class="checkItem" v-for="(itemR,index) in item.radioArray">
                    <input @change="radioChange(item)" v-model="props.modelValue[item.name]" :id="item.name+index" :value="itemR.text" :type="item.type"><label :for="item.name+index">{{itemR.text}}</label>
                  </p>
                </div>
                <div v-if="item.type==='search'" >
                  <input class="inputItem select" @blur="inputBlur(item)" :placeholder="item.plalceHolder"  type="text" readonly unselectable="on" onfocus="this.blur()" v-model.trim='props.modelValue[item.name]'>
                  <div v-if="searchFlag"  class="searchBg" @click.stop="closeSearch(item.name)"></div>
                    <div v-if="searchFlag" class="searchBox">
                      <span class="searchTitle">医院名称</span>
                      <input class="searchInput" type="text" v-model.trim="search" placeholder="请输入医院名称/关键字/关键词">
                      <div class="searchBntBox">
                        <p class="searchTips">提示：输入关键字或者关键词，即可进行搜索。</p>
                        <span @click.stop="searchInput()" class="searchBnt">搜索</span>
                      </div>
                      <div class="searchList">
                        <div class="listTop">
                          <span class="disc">序号</span>
                          <span class="hospital">医院信息</span>
                        </div>
                        <ul v-if="item.searchData&&item.searchData.length>0">
                          <li @click.stop="checkSearch(itemh,item.name)" v-for="(itemh,index) in item.searchData">
                            <span class="disc">{{index+1}}</span>
                            <span class="hospital">{{itemh.hospitalName}}</span>
                          </li>
                          <li class="noMore">
                            <img src="../assets/images/noMore.png" alt=""/>
                            <p>已加载全部内容</p>
                          </li>
                        </ul>
                        <div v-if="!item.searchData"></div>
                        <div class="searchdataNull" v-if="item.searchData&&item.searchData?.length===0">
                          <img src="../assets/images/searchDataNull.png" alt=""/>
                          <p>暂无搜索内容，请确认搜索信息！</p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <span class="errorMsg" v-if="item.vIF&&item.require&&((!props.modelValue[item.name]&&props.modelValue[item.name]!==0)||props.modelValue[item.name]===''||props.modelValue[item.name].length===0)&&(subClick||item.errorJudge)">{{item.errorMsg}}</span>
              <p class="tips" v-if="item.tips">{{item.tips}}</p>
              <p v-if="item.ageJudge&&$router.currentRoute.value.query.type!=='detail'" :class="{ageJudge:true,active:item.ageJudge===1}">未满<br/>18岁</p>
          </template>
        </li>
       </ul>
    </div>
    <card-up v-if="upCardFlag" @sure="upCardSure" @cancel="upcardCancel" :type="upCardType"></card-up>
</template>
<script type="text/babel" lang="ts" setup>
import { ref } from 'vue'
import store from '../store'
import {InfoModel,InputListItem,CardData} from './interface/infoItem-d'
let props = defineProps<{
      infoModel:InfoModel,
      modelValue?:any,
      subClick?:boolean
}>()
const emit = defineEmits<{
	(e: 'update:modelValue', v:any): void
  (e:'inputBlur',v:InputListItem):void
  (e:"radioChange",v:InputListItem):void
  (e:'dateChoose',v:InputListItem):void
  (e:"searchInput",v:string):void
  (e:'selectClick',v:InputListItem):void
  (e:'question',v:InputListItem):void
  (e:'searchInit',v:string):void
}>()
const inputBlur=(item:InputListItem)=>{
   emit('inputBlur',item)
}
const LiClick=(item:InputListItem)=>{
  if(item.type==='search'){
    search.value=''
    searchFlag.value=true;
    document.body.style.cssText="height:100%;overflow:hidden";
    emit("searchInit",'')
  }
  
}
const question=(item:InputListItem)=>{
  emit('question',item)
}
const radioChange=(item:InputListItem)=>{
  emit('radioChange',item)
}
const select=(item:InputListItem)=>{
  props.modelValue.selectType=item.name;
  emit('update:modelValue', props.modelValue)
  if(item.type==='date'){
    emit('dateChoose',item)
  }else{
    emit("selectClick",item)
    props.modelValue.selectFlag=true;
  }
}
const upCardType=ref('身份证');
const upCard=(type:string)=>{
  upCardType.value=type;
  upCardFlag.value=true;
}
const upCardFlag=ref(false);
const upCardSure=(data:CardData)=>{
  const {huk,...newData}=data;
  if(upCardType.value==="上传身份证"){
    store.dispatch("idCardOCR",newData).then((res:any)=>{
      props.modelValue.idCradImageList=[
        {"materialType":1,"materialName":"身份证正面",'materialLink':newData.idcardFrontBase64},
        {"materialType":2,"materialName":"身份证背面",'materialLink':newData.idcardBackBase64}
      ]
      const ocrData=res.data;
      props.modelValue.userName=ocrData.name;
      props.modelValue.idNo=ocrData.idcardNum;
      props.modelValue.age=ocrData.age;
      if(ocrData.year!==''&&ocrData.month!==""&&ocrData.day!==""){
        props.modelValue.birthDate=ocrData.year+"-"+ocrData.month+"-"+ocrData.day;
      }
      props.modelValue.idTypeName="身份证";
      props.modelValue.idType=1;
      upCardFlag.value=false;
      
    })
  }else if(upCardType.value==="上传居民户口簿"){
    props.modelValue.idCradImageList=[
        {"materialType":3,"materialName":"户口本",'materialLink':huk}
      ]
    props.modelValue.idTypeName="居民户口簿";
    props.modelValue.idType=2;
    upCardFlag.value=false;
  }
}
const upNew=(item:any)=>{
  props.modelValue[item.name]=[];
}
const upcardCancel=()=>{
  upCardFlag.value=false;
}
// 搜素
const searchFlag=ref(false)
const search=ref();
const searchInput=()=>{
  emit('searchInput',search.value)
}
const closeSearch=(name:string)=>{

  props.infoModel.inputList.forEach((it:InputListItem)=>{
    if(it.name===name){
      it.errorJudge=true;
      it.searchData=undefined;
      searchFlag.value=false;
    }
  })
  // searchFlag.value=false;
  document.body.style.cssText="overflow:auto;height:auto";
  
}
const checkSearch=(item:any,name:string)=>{
   props.modelValue[name]=item.hospitalName;
   console.log(item)
   document.body.style.cssText="overflow:auto;height:auto"
   searchFlag.value=false
}
</script>
<style type="text/css" scoped>
  .detial .infoBox{
    text-align: right;
    color: #B5B5B5;
  }
  .detial .certificateShow {
    justify-content: flex-end;
  }
  .detial .certificateShow img{
    margin: 0 0 0 2vw;
  }
  .detial .infoBox li .inputBox{
    display: flex;
    justify-content: space-between;
  }
  .detial .infoBox li .itemTitle{
    border-right: none;
    width: auto;
    word-break: keep-all;
  }
  .detial .inputItem{
    width: auto;
  }
  .infoItem{
    width: 94.67vw;
    background: #fff;
    border: 0.13vw solid #DDDDDD;
    box-shadow: 0vw 1vw 0vw 0vw rgba(0, 0, 0, 0.05);
    border-radius: 3vw;
    margin: auto;
  }
  
.infoBox li.error{
    border-bottom:#FF7173 solid 0.53vw!important;
  }
  .modelTitle{
    width: 81.07vw;
    margin: auto;
    display: block;
    font-size: 4vw;
    color: #000;
    font-weight: bold;
    margin-top: 5.07vw;
    position: relative;
  }
  .modelTitle:before{
    position: absolute;
    left: -4vw;
    top:0.8vw;
    display: block;
    content: "";
    height: 4vw;
    width: 1.33vw;
    border-radius: 5vw;
    background: #009F4F;
  }
  .infoBox li{
    width: 81.07vw;
    margin: auto;
    border-bottom: #E5E5E5 solid 1px;
    padding: 4vw 0 2vw 0;
    position: relative;
  }
  .infoBox li.styleNull{
    padding: 0;
    border:none;
  }
  .inputBox{
    display: flex;
    align-items: center;
  }
  .infoBox li:last-child{
    border-bottom: none;
  }
  .infoBox li .itemTitle{
    font-size: 3.73vw;
    color: #000;
    display: block;
    width: 17vw;
    text-align: justify;
    text-align-last: justify;
    padding: 0 2vw 0 1.5vw;
    /* height: 6.4vw; */
    line-height: 6.4vw;
    border-right: #E5E5E5 solid 1px;
  }
  .inputItem{
    margin-left: 2vw;
    border:none;
    width:58.8vw;
    font-size: 3.73vw;
    word-break: break-all;
  }
  .inputItem.select{
    width: 56.8vw;
  }
  ::placeholder{
    color: #B5B5B5
  }
  .certificateBox{
    display: flex;
    margin-left: 4vw;
    justify-content: space-between;
    width:56vw;
  }
  .certificateBox span{
    font-size: 3.73vw;
    height: 6.27vw;
    line-height: 6.27vw;
    padding: 0 1.33vw;
    background: #008AFF;
    color: #fff;
    text-align: center;
    border-radius: 1vw;
  }
  .tips{
    display: block;
    color: #FF7173;
    margin-top: 3.7vw;
    margin-left: 1.5vw ;
  }
  input:disabled{
    background: none;
    border:none;
    outline: none;
  }
  input.select{
    background: url(../assets/images/right.png) no-repeat right center;
    background-size: 1.73vw 2.53vw;
  }
 textarea{
    width: 54.66vw;
    height: 26.4vw;
    background: #F9F9F9;
    border: 0.13vw solid #E5E5E5;
    border-radius: 1vw;
    margin-left: 2vw;
    font-size:3.73vw;
    padding: 2.8vw 3.47vw;
 }
 input[type="radio"]{
   height: 4.93vw;
   width: 4.93vw;
   display: block;
   background: url(../assets/images/checkD.png) no-repeat;
   background-size: 4.93vw;
 }
 input[type="radio"]:checked{
    background: url(../assets/images/checked.png) no-repeat;
    background-size: 4.93vw;
 }
 .checkBox p {
    display: flex;
    align-items: center;
    margin-left: 6vw;
 }
 .checkBox{
   display: flex;
 }
.checkBox p label{
  font-size: 3.73vw;
  color: #000;
  margin-left: 4.13vw;
}
.infoBox li.must:before{
  display: block;
  content: "*";
  color: #FF0000;
  font-size: 5vw;
  position:absolute;
  left: -2vw;
  top:50%;
  margin-top: -1vw;

}
.infoBox li.must.styleNull:before{
  color: transparent;
}
.search{
  position: relative;
}
.searchBg{
  position: fixed;
  top:0vw;
  left: 0vw;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.searchBox{
  position: fixed;
  bottom:0vw;
  left: 0vw;
  width: 100vw;
  height: 113vw;
  background: #FFFFFF;
  z-index: 1000
}
.searchTitle{
  display: block;
  width: 92.27vw;
  font-size: 3.47vw;
  color: #000;
  margin: auto;
  padding: 5.7vw 0 2.13vw 0;
}
.searchInput{
  display: block;
  width: 92.26vw;
  height: 6.8vw;
  margin: auto;
  font-size: 3.43vw;
  padding-left: 1.87vw;
  border: 0.13vw solid #E4E4E4;
  background: #F4F4F4;
  border-radius: 1.33vw;
}
.searchBntBox{
  width: 92.26vw;
  margin:4vw auto 5.33vw auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
} 
.searchTips{
  color:#FC9601
}
.searchBnt{
  width: 17.87vw;
  height: 7.07vw;
  background: #FC9601;
  border-radius: 3.47vw;
  text-align: center;
  line-height: 7.07vw;
  font-size: 3.47vw;
  color: #fff;
}
.listTop{
  width: 89.73vw;
  height: 8.8vw;
  margin: auto;
  background:#009F4F;
  border-radius: 1.33vw;
  margin-top: 1.73vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 8.8vw; */
  color: #fff;
}
.searchList .listTop .disc{
  border-right-color:#fff;
  height: 50%;
  color: #fff;
}
.searchList .listTop .hospital{
  text-align: center;
  text-indent: -15vw;
}
.searchList .disc{
  display: block;
  width: 14.79vw;
  font-size: 3.47vw;
  text-align: center;
  border-right: #DADADA solid 1px;
  color: #B5B5B5;
}
.searchList .hospital{
  display: block;
  width:69vw;
  font-size: 3.47vw;
  margin-left: 3vw;
}
.searchList{
  width: 92.27vw;
  margin: auto;
  box-shadow: 0 0 1vw 0 rgba(14, 5, 10, 0.1);
  border-radius: 1.33vw;
  overflow: hidden;
}
.searchList ul{
  width: 89.73vw;
  margin: auto;
  height: 67vw;
  overflow-x:hidden;
  overflow-y: auto;
}
.searchList ul li{
  width: 89.73vw;
  display: flex;
  justify-content: center;
  border-bottom:#E5E5E5 dashed 1px;
}
.searchList ul li.noMore{
  display: block;
}
.noMore img{
  width: 4.8vw;
  display: block;
  margin: 1vw auto 2.56vw auto;
}
.noMore p{
  color: #B5B5B5;
  font-size:2.93vw;
  text-align: center;
}
.searchdataNull img{
  width: 12.27vw;
  display: block;
  margin:21vw auto 5.87vw auto;
}
.searchdataNull p{
  text-align: center;
  font-size: 3.47vw;
  color: #B5B5B5;
  padding-bottom: 23vw;
}
.certificateShow{
  display: flex;
  justify-content: space-between;
  width: 60vw;
}
.certificateShow .imgBox{
  display: flex;
}
.certificateShow .upNew{
  font-size: 3.73vw;
  color: #008AFF;
  background: none;
  font-weight: bold;
}
.certificateShow img{
  display: block;
  height: 6.27vw;
  width: 10vw;
  margin-right: 2vw;;
}
.question{
  position: absolute;
  background: #fff;
  width: 4vw;
  display: block;
  right: 3vw;
  top: 50%;
  margin-top: -2vw;
}
.ageJudge{
  position: absolute;
  right: 0;
  top:2vw;
  color: #B5B5B5;
  font-size: 2.93vw;
  background: url(../assets/images/checkD.png) no-repeat 0 center;
  padding-left: 8vw;
  background-size:6vw ;
}
.ageJudge.active{
  background: url(../assets/images/checked.png) no-repeat 0 center;
  background-size:6vw ;
}
.errorMsg{
  color: #FF4A4A;
  font-size: 2.93vw;
  padding-left: 23vw;
}
</style>