<template>
    <div v-cloak id="apply" :class="{detail:pageType==='detail'||pageType==='up1'||pageType==='up2'}">
		<div class="tabBox" v-if="pageType!=='detail'&&pageType!=='up1'&&pageType!='up2'">
			<ul class="tab">
				<li :class="{current:progressCode>=item.code}"  v-for="item in tabData">{{item.name}}</li>
			</ul>
			<img class="progress" alt="" :src="progressCode===1?arrow:arrowAll">
		</div>
		<router-view></router-view>
	</div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import {useStore} from 'vuex';
import { onBeforeRouteUpdate} from "vue-router";
import arrow from "../assets/images/arrow.png";
import arrowAll from "../assets/images/arrowAll.png"

import router from '../router';
const tabData=[
	{"code":1,"name":"报案信息"},
	{"code":2,"name":"上传资料"}
]
const pageType=router.currentRoute.value.query.type;
const progressCode=ref(1);
progressCode.value=router.currentRoute.value.name==="uploading"?2:1;
onBeforeRouteUpdate(to => {
	progressCode.value=to.name==="uploading"?2:1;
});
const stroe=useStore;
</script>
<style scoped>
#apply{
	min-height: 100vh;
	background: #F7F7F7;
	padding: 13.4vw 0;
}
#apply.detail{
	padding-top: 0;
	border-top: #50AFFF solid 0.53vw;
}
.tabBox{
    height: 12.4vw;
   	background: #fff;
   	border-bottom: #009F4F solid 0.53vw;
	box-shadow: #fff 0 0.2vw 0vw, #ececec 0.5vw 0 0 1vw;
	position:fixed;
	left: 0;
	top:0;
	width: 100%;
	z-index: 999;
}
.tab{
	width: 88vw;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}
.tab li{
	height:7.6vw;
	width: 26.6vw;
	font-size: 3.73vw;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url(../assets/images/applyProgressDis.png) no-repeat;
	background-size: 31.6vw 7.6vw;
	position: relative;
	padding-left: 5vw;
	/* text-indent: 15vw; */
	
}
.tab li.current{
	background: url(../assets/images/applyProgressActive.png) no-repeat;
	color: #fff;
	background-size: 31.6vw 7.6vw;
}
.tab li:before{
	color: #E5E5E5;
	font-weight: bold;
	font-size: 4.27vw;
	display: block;
	position: absolute;
	z-index: 10;
	left:2.5vw;
}
.tab li.current:before{
	color:#009F4F ;
}
.tab li:nth-child(1):before{
	content: "1";
}
.tab li:nth-child(2):before{
	content: "2";
}
.progress{
	width: 15.73vw;
	height: 1.87vw;
	position: absolute;
	top: 5.5vw;
	left: 50%;
	margin-left: -7.865vw;
}
</style>