import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import './assets/css/reset.css';
import {getState} from './utils'
import Vant from 'vant';
import 'vant/lib/index.css';

const app=createApp(App)
// app.config.globalProperties.$Fn = Fn;
app.config.globalProperties.getState = getState;
app.use(Vant);
app.use(store).use(router).mount('#app')
declare module 'vue' {
	export interface ComponentCustomProperties {
		$getState: typeof getState
	}
}